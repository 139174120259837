export default {
	Dictionary: {
		emptyModel: 'Empty model',
		notReadAccess: 'You do not have access to view this dictionary',
		addRecord: 'Add record',
		editRecord: 'Edit record',
		errorSave: 'Error saving record',
		errorDelete: 'Error delete record',
	},
};
