import { observable } from 'mobx';
import { DIALOG_SOURCES, DIALOG_TABS, INCLUDES_SINGLE_DIALOG, NEW_CONTRAGENT } from '@pages/messages/dialogs/constants';
import store from 'client/store';
import { addAlertNotification, addSuccessNotification } from '@smartplatform/consta/ui/Notifications';

export class SingleDialogStore {
	@observable isLoading = true;
	@observable dialog;
	@observable editMode = false;
	@observable categoryERP = null;
	@observable relationRecords = [];
	@observable contragent = null;
	@observable showCreateContragent = false;
	@observable activeTab = DIALOG_TABS.MESSAGES;
	@observable historyOrder = 'id desc';
	dialogId;

	constructor(dialogId) {
		this.dialogId = dialogId;
		this.id = dialogId;
		this.init();
	}

	init = async () => {
		this.dialog = this.dialogId ? await store.model.Dialog.findById(this.dialogId, { include: INCLUDES_SINGLE_DIALOG }) : null;
		if (this.dialog) {
			const tasks = this.dialog.tasks();
			const orders = this.dialog.orders();
			const documents = this.dialog.documents();
			const mergedRecords = [...tasks, ...orders, ...documents];
			this.relationRecords = mergedRecords.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
			if (this.dialog.employeeId && this.dialog.employee.contragentId)
				this.contragent = await store.model.Contragent.findById(this.dialog.employee.contragentId);
		}
		this.isLoading = false;
	};

	onChangeDialog = (prop) => (value) => {
		if (this.dialog.hasOwnProperty(prop)) this.dialog[prop] = value;
	};
	onChangeDialogAndSave = (prop) => async (value) => {
		if (this.dialog.hasOwnProperty(prop)) this.dialog[prop] = value;
		await this.save();
	};

	onRelationChange = (prop) => async (value) => {
		if (prop) {
			let propId = `${prop}Id`;
			this.dialog[prop] = value;
			this.dialog[propId] = value?.id || value;
		}
		this.save();
	};

	onChange = (prop) => async (value) => {
		if (this.hasOwnProperty(prop)) this[prop] = value;
	};

	onChangeCategory = (value) => {
		this.categoryERP = value;
	};

	toggleEditMode = () => (this.editMode = !this.editMode);

	save = async () => {
		await this.dialog.save();
	};

	addRelationRecord = () => {
		if (store.model[this.categoryERP]) {
			this.relationRecords.unshift(new store.model[this.categoryERP]({ dialogId: this.dialogId }));
			this.categoryERP = null;
		}
	};

	removeRelationRecord = async (index) => {
		const record = this.relationRecords[index];
		this.relationRecords.splice(index, 1);
		if (record.id) await record.delete();
	};

	createEmployee = async () => {
		if (!this.contragent?.id) return;
		try {
			const newEmployeeData = {
				contragentId: this.contragent.id,
				personName: this.dialog.name,
				externalId: this.dialog.source.code === DIALOG_SOURCES.EMAIL ? this.dialog.email : this.dialogId.externalId,
			};
			if (this.dialog.phone) newEmployeeData.phone = this.dialog.phone;
			if (this.dialog.email) newEmployeeData.email = this.dialog.email;

			if (this.dialog.employee) await this.dialog.employee.delete();
			const employee = await store.model.Contragent.create(newEmployeeData);
			let dialogByEmployee = [this.dialog];
			if (this.dialog.email) {
				const dialogByEmail = await store.model.Dialog.find({ where: { email: this.dialog.email } });
				dialogByEmployee = [...dialogByEmployee, ...dialogByEmail];
			}
			for (const _dialog of dialogByEmployee) {
				_dialog.employee = employee;
				await _dialog.save();
			}
			addSuccessNotification(t('record.saved'));
		} catch (error) {
			addAlertNotification(error.message);
		}
	};

	onChangeContragent = (contragent) => {
		if (contragent === NEW_CONTRAGENT) {
			this.showCreateContragent = true;
		} else {
			this.onChange('contragent')(contragent);
		}
	};

	onCloseContragentPopup = () => {
		this.showCreateContragent = false;
	};

	onSaveContragent = (contragent) => {
		this.contragent = contragent;
		this.showCreateContragent = false;
	};

	onTabChange = (tabName) => {
		this.activeTab = tabName;
	};

	onChangeHistoryOrder = () => {
		this.historyOrder = this.historyOrder === 'id desc' ? 'id asc' : 'id desc';
	};
}

