import React from 'react';
import { observer } from 'mobx-react'; // React Data Grid Component
import { getSideBar } from './sidebar/getSideBar';
import { AgGrid as _AgGrid } from '@smartplatform/consta/ui/AgGrid';
import appStore from '@appStore';
import { observable } from 'mobx';
import { addResourceBundle } from './i18n';
import { t } from './i18n';
addResourceBundle();
import PropTypes from 'prop-types';

@observer
export class AgGrid extends React.Component {
	gridRef = React.createRef();
	sideBar = null;
	@observable key = 0;

	static propTypes = {
		code: PropTypes.string,
		withEditAccess: PropTypes.boolean,
	};

	constructor(props) {
		super(props);
		this.initialState = appStore.local.agGrid[props.code];
		if (this.initialState?.rowGroupExpansion) {
			delete this.initialState.rowGroupExpansion;
		}

		this.setSideBar();
	}

	setSideBar = () => {
		const sideBarProps = { gridRef: this.gridRef, templateCode: this.props.code, withEditAccess: this.props.withEditAccess };
		if (this.props.sideBar !== null) {
			this.sideBar = this.props.sideBar ? this.props.sideBar(sideBarProps) : getSideBar(sideBarProps);
		}
	};
	componentDidMount() {
		if (this.props.gridRef) this.props.gridRef.current = this.gridRef.current;
	}
	// чтобы не повторять типичные пропы в каждой табличке

	// добавляет дополнительные пункты в контекстное и меню столбцов
	getMenuItems = ({ defaultItems, api }) => {
		const { code } = this.props;
		let result = defaultItems;
		if (code) {
			result = [
				...defaultItems,
				{
					name: t('saveSettings'),
					action: () => {
						appStore.local.agGrid[code] = api.getState();
						appStore.local.save();
					},
				},
				{
					name: t('resetSettings'),
					action: () => {
						appStore.local.agGrid[code] = {};
						appStore.local.save();
						// проще перендерить уже без стейта
						this.setSideBar();
						this.initialState = {};
						this.key++;
					},
				},
			];
		}

		return result;
	};

	render() {
		const isDarkMode = appStore.local.theme !== 'DEFAULT';
		const { floatingFilter } = appStore.local.agGrid || {};
		const theme = 'alpine';
		const {
			defaultColDef,
			showSideBar,
			sideBar,
			pagination = false,
			statusBar = { statusPanels: [{ statusPanel: 'agTotalRowCountComponent' }, { statusPanel: 'agFilteredRowCountComponent' }] },
			...restProps
		} = this.props;
		const _defaultColDef = { floatingFilter, ...defaultColDef };

		return (
			<_AgGrid
				{...restProps}
				key={this.key}
				theme={theme}
				sideBar={this.sideBar} // кастомный сайдбар
				gridRef={this.gridRef} // ref для получения доступа к апи
				isDarkMode={isDarkMode}
				defaultColDef={_defaultColDef}
				initialState={this.initialState}
				getContextMenuItems={this.getMenuItems}
				getMainMenuItems={this.getMenuItems}
				statusBar={statusBar}
				pagination={pagination}
			/>
		);
	}
}
