import { User } from './User';
import t from 'i18n';
import { Text } from '@consta/uikit/Text';
import './renderUser.scss';

const STYLE = { cursor: 'pointer' };

const defaultOptions = {
	replaceMe: true,
};

export const renderUser = (user, options = defaultOptions) => {
	if (!user) return;
	const props = {};
	let style;
	if (user.item) {
		// renderValue
		const { item, handleRemove } = user;
		props.user = item;
		style = STYLE;
		//! тут надо будет дописать логику крестик
		// props.onCancel = handleRemove;
	} else {
		// getItemLabel
		props.user = user;
	}
	const info = props.user.isBlocked ? <Text view='alert'>{t('user.isBlocked')}</Text> : options.info;

	// label - условный лейбл для тех записей, которые не имеют аватара
	if (!props.user.id) return props.user.label;

	const size = options.size || 'm';

	const width = options.width || 'default';

	return (
		<User
			key={props.user.id}
			meLabel={options.meLabel}
			replaceMe={options.replaceMe}
			user={props.user}
			size={size}
			className='renderUser'
			info={info}
			style={style}
			width={width}
		/>
	);
};

