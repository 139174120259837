import React from 'react';
import './ColorValue.scss';

const DEFAULT_COLOR = '#ddd';
const DEFAULT_EMPTY = <span className='ColorValue' style={{ background: DEFAULT_COLOR }} />;

export const ColorValue = ({ value, empty = DEFAULT_EMPTY }) => {
	const style = {
		background: value || 'none',
		border: `1px solid ${value || DEFAULT_COLOR}`,
	};
	return value ? <span className='ColorValue' style={style} /> : empty;
};
