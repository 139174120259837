import React, { Component, createRef } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';
import { ProductFeatureValue } from './ProductFeatureValue';
import { FeatureSelector } from '../../feature-selector';
import classNames from 'classnames';
import autoAnimate from '@formkit/auto-animate';
import { Section } from '@features/Section';

@inject('productStore')
@observer
export class Features extends Component {
	@observable isContentHide = false;

	constructor(props) {
		super(props);
		this.store = props.productStore;
		this.product = this.store.product;
		this.ref = createRef();
	}

	componentDidMount() {
		this.ref.current && autoAnimate(this.ref.current);
	}

	onCollapseSection = () => {
		this.isContentHide = !this.isContentHide;
	};

	render() {
		const { product } = this;
		const { productFeatures } = this.store;

		return (
			<Section title={t('product.features')}>
				<div ref={this.ref}>
					{!this.isContentHide && (
						<div className={classNames('product-features')}>
							<div>
								<FeatureSelector featureType='product' />
								{productFeatures.map((productFeature) => (
									<ProductFeatureValue
										key={productFeature.id || 'new'}
										product={product}
										feature={productFeature.feature}
									/>
								))}
							</div>
						</div>
					)}
				</div>
			</Section>
		);
	}
}

