export default {
	KanbanCard: {
		count: 'Total',
		sum: 'Price',
		positionCount: 'Positions',
		showAll: 'Show all',
		showLess: 'Hide',
		overdue: 'expired',
	},
};

