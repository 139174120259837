import React, { createRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Toolbar } from 'components';
import { EditRecord } from './EditRecord';
import { addResourceBundle } from './i18n';
import { withTranslation } from 'react-i18next';
import './Dictionary.scss';
import { AgGrid } from '@features/AgGrid';
import { EXCLUDED_FIELDS } from './constants';
import { observable } from 'mobx';
import { getLabelName } from 'client/tools';
import { lowerFirst } from 'lodash';
import { ColorValue } from '@features/ColorPicker';
addResourceBundle();

/*
 * listProperties - массив полей модели для отображения в списке example: ['name', 'date']
 * listRelations - массив для отображения реляций в списке example: [{relation: 'list', property: 'name', computed: (record) => {}}]
 * editRelations - массив редактируемых реляций, все кроме belongsTo отсекаются, даже если переданы 
 * example: [{relation: 'list', property: 'name', computed: (record) => {} , getFilter: (record) => {}]
 * editProperties - массив редактируемых полей модели ['name', 'date'], 
 * пропы так же могут быть объектами {name: 'name', computed: (record) => {} , getDisabled: (record) => {} , getLabel: (record) => {}}
 * filterProperties - массив пропов у которых будет включена фильтрация ['name', 'description']
 * newRecordData - начальные данные новой записи
 * filter - фильтр
 * model - модель modelStore
 * getDisableSave - функция дизейбла формы сохранения , которая в аргументе имеет record
 * checkRequiredFields - функция проверки обязательных полей формы
 * */
@withTranslation('components', { keyPrefix: 'Dictionary' })
@observer
export class Dictionary extends React.Component {
	static propTypes = {
		model: PropTypes.string.isRequired,
		listProperties: PropTypes.array,
		listRelations: PropTypes.array,
		editRelations: PropTypes.array,
		editProperties: PropTypes.array,
		excludeRelations: PropTypes.array,
	};

	@observable records = [];
	originalRecord = null;
	@observable singleRecord = null;
	@observable isLoading = true;

	agGridColDefs = [
		{
			headerName: 'id',
			cellDataType: 'number',
			field: 'id',
			suppressMenu: true,
			width: 128,
		},
	];

	filterInclude = [];
	fieldsForEdit = [];
	relationsForEdit = [];
	ref = createRef();

	constructor(props) {
		super(props);
		this.init();
	}

	init = () => {
		let { listProperties, listRelations, editProperties, editRelations } = this.props;
		const { PROPERTIES, RELATIONS } = this.props.model;

		const modelForeignKeys = Object.values(RELATIONS).map(({ foreignKey }) => foreignKey);

		// Перебираем свойства модели добавляем их к редактируемым, в список и в фильтр для запроса
		for (const property in PROPERTIES) {
			// Валидация поля для списка
			// Если массив полей для списка не передан, то добавляем все в список кроме тех полей и ключей реляций, иначе только те что были переданы
			let isValidListField = true;
			if (!listProperties) {
				const accessProperty =
					![...EXCLUDED_FIELDS, ...modelForeignKeys].includes(property) &&
					!['Object', 'Geography'].includes(PROPERTIES[property].type);
				if (!accessProperty) isValidListField = false;
			} else {
				const listPropertiesNames = listProperties.map((property) => property?.name || property);
				if (!listPropertiesNames.includes(property)) {
					isValidListField = false;
				}
			}
			if (isValidListField) {
				const propertyProps = listProperties?.find((prop) => prop?.name === property) || {};

				const colProps = {
					headerName: getLabelName(property, this.props.model.INFO.name),
					cellDataType: lowerFirst(PROPERTIES[property].type),
					field: property,
					suppressMenu: true,
					filter: this.props.filterProperties?.includes(property),
					flex: 1,
				};
				if (property === 'color') {
					colProps.cellDataType = 'object';
					colProps.cellRenderer = ({ value }) => <ColorValue value={value} />;
				}

				if (propertyProps.computed) {
					colProps.valueFormatter = ({ value }) => propertyProps.computed(value);
				}
				this.agGridColDefs.push(colProps);
			}

			// Валидация для полей редактирования. Если не переданы editProperties, исключаем тех поля и ключи реляций, иначе допускаем только переданные
			let isValidEditField = true;
			if (editProperties && editProperties.length) {
				// пропы могут переданы как строкой так и объектом
				const editPropertiesNames = editProperties.map((property) => property?.name || property);
				if (!editPropertiesNames.includes(property)) isValidEditField = false;
			} else {
				if ([...modelForeignKeys, ...EXCLUDED_FIELDS].includes(property)) isValidEditField = false;
			}
			if (isValidEditField) {
				// ессли передан как объект, надо прокинуть пропсы дальше
				const propertyProps = editProperties?.find((prop) => prop?.name === property) || {};
				this.fieldsForEdit.push({ key: property, ...PROPERTIES[property], ...propertyProps });
			}
		}

		// Перебираем реляции модели
		const relationsData = {};
		for (const relationModel in RELATIONS) {
			// Если реляция не belongsTo, то сразу же пропускаем
			if (RELATIONS[relationModel].type !== 'belongsTo' || RELATIONS[relationModel].name === 'owner') continue;

			// Валидация для списка
			const findListRelation = listRelations && listRelations.find(({ relation }) => relation === RELATIONS[relationModel].name);
			if (findListRelation) {
				const { relation, property, properties = [], computed } = findListRelation;
				const render = (value) => this.getRelationRender(value, property, computed);
				this.agGridColDefs.push({
					headerName: getLabelName(property, RELATIONS[relation].model),
					field: relation,
					suppressMenu: true,
					flex: 1,
					cellRenderer: ({ value }) => render(value),
					valueFormatter: ({ value }) => render(value),
				});
				const items = property ? [property] : properties.filter(Boolean);
				relationsData[RELATIONS[relationModel].name] = [...(relationsData[RELATIONS[relationModel].name] || []), ...items];
			}

			// Валидация для редактирования
			const findEditRelation = editRelations && editRelations.find(({ relation }) => relation === RELATIONS[relationModel].name);
			if (findEditRelation) {
				const { property = 'name', properties = [] } = findEditRelation;
				this.relationsForEdit.push({ ...findEditRelation, ...RELATIONS[relationModel] });
				const items = Array.from(new Set([property, ...properties])).filter(Boolean);
				relationsData[RELATIONS[relationModel].name] = [...(relationsData[RELATIONS[relationModel].name] || []), ...items];
			}
		}

		// прогоняем через сет и формируем итоговые фильтры для реляций и требуемых полей
		this.filterFields = Array.from(new Set(this.filterFields));
		for (const relationKey in relationsData) {
			const fields = Array.from(new Set(relationsData[relationKey]));
			this.filterInclude.push({ relation: relationKey, scope: { fields: fields } });
		}

		this.fetchRecords();
	};

	fetchRecords = async () => {
		this.isLoading = true;
		const findProps = { ...(this.props.filter || {}) };
		if (this.filterInclude.length) findProps.include = this.filterInclude;
		this.records = await this.props.model.find(findProps);
		this.isLoading = false;
	};

	getRelationRender = (record, property, computed) => {
		let result = null;
		if (record) {
			if (computed) {
				result = computed(record);
			} else {
				result = record[property];
			}
		}
		return result;
	};

	onCreate = () => {
		this.singleRecord = new this.props.model({ ...(this.props.newRecordData || {}) });
	};

	onDoubleClickRow = ({ data: record }) => {
		this.singleRecord = record;
		this.originalRecord = new this.props.model(JSON.parse(JSON.stringify(record)));
	};

	onCloseSidebar = () => {
		if (this.originalRecord) {
			const findIndex = this.records.findIndex(({ id }) => id === this.singleRecord.id);
			if (findIndex !== -1) this.records[findIndex] = this.originalRecord;
			this.reloadAgGrid();
		}
		this.singleRecord = null;
		this.originalRecord = null;
	};

	onSave = () => {
		if (!this.originalRecord) {
			this.records.push(this.singleRecord);
		}
		this.singleRecord = null;
		this.originalRecord = null;
		this.reloadAgGrid();
	};

	onDelete = () => {
		const findIndex = this.records.findIndex(({ id }) => id === this.singleRecord.id);
		if (findIndex !== -1) this.records.splice(findIndex, 1);
		this.singleRecord = null;
		this.originalRecord = null;
		this.reloadAgGrid();
	};

	reloadAgGrid = () => {
		const gridApi = this.ref.current.api;
		gridApi.setGridOption('rowData', this.records);
	};

	render() {
		const { model, focusProperty, t: widgetTranslate, checkRequiredFields, getDisableSave } = this.props;

		if (!model) return widgetTranslate('emptyModel');
		if (!model.INFO.READ) return widgetTranslate('notReadAccess');

		const {
			isLoading,
			records,
			agGridColDefs,
			onCreate,
			onDoubleClickRow,
			fieldsForEdit,
			relationsForEdit,
			onCloseSidebar,
			singleRecord,
			onSave,
			onDelete,
			ref,
		} = this;

		const editProps = {
			model,
			fieldsForEdit,
			relationsForEdit,
			onCloseSidebar,
			record: singleRecord,
			onSave,
			onDelete,
			focusProperty,
			getDisableSave,
			checkRequiredFields,
		};

		return (
			<div className='Dictionary'>
				<Toolbar className='Dictionary-toolbar'>{model.INFO.WRITE && <Toolbar.CreateButton onClick={onCreate} />}</Toolbar>
				<AgGrid
					className='Dictionary-list'
					columnDefs={agGridColDefs}
					rowData={records}
					loading={isLoading}
					rowGroupPanelShow='never'
					pagination={false}
					sideBar={null}
					onRowDoubleClicked={onDoubleClickRow}
					gridRef={ref}
					defaultColDef={{
						enableValue: false, // агрегация
						enableRowGroup: false, // группировка для столбца
						enablePivot: false, // пивот(значения в столбцах)
						filter: false,
					}}
				/>
				<EditRecord {...editProps} />
			</div>
		);
	}
}
