import { UserAvatar } from '@smartplatform/consta/ui/UserAvatar';
import store from '@appStore';
import t from 'i18n';
export const User = ({ user, replaceMe = true, meLabel, ...restProps }) => {
	if (!user) return;

	//? опционально может сделать?
	if (replaceMe) {
		if (store.model.user.id === user.id) {
			const name = meLabel || t('me');
			user = new store.model.User({ avatar: store.model.user.avatar, id: store.model.user.id, lastName: name });
		}
	}

	return <UserAvatar {...restProps} user={user} view='clear' fioShort />;
};
