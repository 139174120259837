export default {
	HeaderBreadcrumbs: {
		main: 'Главная',
		projects: 'Проекты',
		project: 'Проект',
		newProject: 'Новый проект',
		tasks: 'Задачи',
		task: 'Задача',
		newTask: 'Новая задача',
		managementSettings: 'Настройки модуля Управление',
		salesSettings: 'Настройки модуля Продажи',
		manufactureSettings: 'Настройки модуля Производство',
		documentsSettings: 'Настройки модуля Документы',
		messagesSettings: 'Настройки модуля Сообщения',
		storehouseSettings: 'Настройки модуля Склад',
		contragentsSettings: 'Настройки модуля Контрагенты',
		adminSettings: 'Настройки приложения',
		staffSettings: 'Настройки модуля Сотрудники',
		financeSettings: 'Настройки модуля Финансы',
		test: 'Тестовая страница',
		profile: 'Настройки профиля',
		analytics: 'Аналитика',
		orders: 'Заказы',
		order: 'Заказ',
		newOrder: 'Новый заказ',
		documents: 'Документы',
		document: 'Документ',
		newDocument: 'Новый документ',
		dialogs: 'Диалоги',
		dialog: 'Диалог',
		newDialog: 'Новый диалог',
		productionOrders: 'Наряды',
		productionOrder: 'Наряд',
		newProductionOrder: 'Новый наряд',
		catalog: 'Каталог',
		reports: 'Отчеты',
		workshop: 'Цех',
		workshops: 'Цеха',
		newWorkshop: 'Новый цех',
		equipments: 'Оборудование',
		equipment: 'Оборудование',
		newEquipment: 'Новое оборудование',
		processes: 'Процессы',
		process: 'Процесс',
		newProcess: 'Новый процесс',
		materials: 'Материалы',
		material: 'Материал',
		newMaterial: 'Новый материал',
		techMaps: 'Технологические карты',
		techMap: 'Технологическая карта',
		newTechMap: 'Новая технологическая карта',
		nomenclatures: 'Номенклатура',
		nomenclature: 'Номенклатура',
		newNomenclature: 'Новая номенклатура',
		nomenclatureMovements: 'Движение номенклатуры',
		nomenclatureMovement: 'Перемещение',
		newNomenclatureMovement: 'Новое перемещение',
		warehouses: 'Склады',
		warehouse: 'Склад',
		newWarehouse: 'Новый склад',
		contragents: 'Контрагенты',
		contragent: 'Контрагент',
		newContragent: 'Новый контрагент',
		staff: 'Сотрудники',
		staffMember: 'Сотрудник',
		newStaffMember: 'Новый сотрудник',
		staffSchedule: 'Штатное расписание',
		users: 'Пользователи',
		user: 'Пользователь',
		newUser: 'Новый пользователь',
		roles: 'Роли',
		role: 'Роль',
		newRole: 'Новая роль',
		operations: 'Операции',
		operation: 'Операция',
		newOperation: 'Новая операция',
		myCompanies: 'Мои компании',
	},
};

