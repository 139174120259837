import { observable } from 'mobx';
import store from 'client/store';
import { addWarningNotification } from '@smartplatform/consta/ui/Notifications';
import t from 'i18n';
import { formatDate } from 'client/tools';

export class TaskFormStore {
	@observable enabledBoardLists = [];
	@observable loadingBoardLists = false;
	@observable isLoading = true;
	@observable task;

	constructor(task, dialog) {
		this.task = task;
		this.dialog = dialog;
		this.init();
	}

	init = async () => {
		this.isLoading = false;
	};

	getDefaultList = async (projectId) =>
		(
			await store.model.BoardListProject.find({
				include: ['boardList'],
				where: { projectId: projectId, boardListId: { neq: null } },
				order: 'default desc nulls last',
				limit: 1,
			})
		)?.[0] || null;

	onChangeProject = async (project) => {
		this.loadingBoardLists = true;
		this.task.boardList = null;
		this.task.project = project;
		this.enabledBoardLists = (await this.task.project?.lists()) || [];
		const defaultBoardList = await this.getDefaultList(project.id);

		if (!defaultBoardList && !this.enabledBoardLists.length) {
			addWarningNotification(t('project.noListsMessage', { projectName: project.name }));
		} else {
			this.task.boardList = defaultBoardList.boardList;
		}
		this.loadingBoardLists = false;
	};

	onDateChange = (dateName) => (value) => {
		this.task[dateName] = value;
	};

	beforeSave = () => {
		const contactUser = this.dialog.name || this.dialog.username || this.dialog.phone || this.dialog.email;
		this.task.name = `${contactUser} ${formatDate(new Date(), 'dd.MM.yyyy HH:mm')}`;
	};
}

