import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import VariantPrice from './VariantPrice';
import store from 'client/store';
import t from 'i18n';
import './style.scss';
import { Text } from '@consta/uikit/Text';

@inject('productStore')
@observer
export default class VariantPrices extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { productStore, nomenclature, onChange } = this.props;
		const { amountRanges } = productStore;

		const ranges = amountRanges.filter((range) => !!range.id);

		return (
			<div className='variant-prices'>
				<Text weight='bold' size='l'>
					{t('nomenclature.priceRange')}
				</Text>
				{ranges.length > 0 && (
					<div className='ranges'>
						{ranges.map((range) => {
							return <VariantPrice key={range.id} range={range} nomenclature={nomenclature} onChange={onChange} />;
						})}
					</div>
				)}
			</div>
		);
	}
}
