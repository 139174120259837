import { RecordSelect } from '@smartplatform/consta/ui/RecordSelect';
import { renderUser } from './renderUser';
import React from 'react';
import classnames from 'classnames';
import store from '@appStore';
import './UserSelect.scss';

export class UserSelect extends React.PureComponent {
	static defaultProps = {
		size: 'm',
	};
	constructor(props) {
		super(props);
		const { size, getItemInfo, getItemLabel, meLabel, renderValue } = this.props;

		if (getItemLabel) {
			this.getItemLabel = (item) => getItemLabel(item, size);
		} else {
			this.getItemLabel = (item) => renderUser(item, { size, width: 'full', meLabel, info: getItemInfo?.(item) });
		}

		if (renderValue) {
			this.renderValue = (item) => renderValue(item, size);
		} else {
			this.renderValue = (item) => renderUser(item, { size, meLabel, info: getItemInfo?.(item.item) });
		}
	}

	defaultFilter = {
		order: 'isBlocked ASC nulls first, lastName asc nulls last',
	};

	render() {
		let { filter, getItemInfo, ...restProps } = this.props;
		const className = classnames('UserSelect', this.props.className);

		// подмена ордера у фильтра
		if (!filter) {
			filter = this.defaultFilter;
		} else if (!filter.order) {
			filter = {
				...filter,
				order: this.defaultFilter.order,
			};
		}

		return (
			<RecordSelect
				{...restProps}
				className={className}
				renderValue={this.renderValue}
				getItemLabel={this.getItemLabel}
				model={store.model.User}
				filter={filter}
			/>
		);
	}
}
