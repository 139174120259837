import React from 'react';
import { Route } from 'react-router-dom';
import { STAFF_PATH, STAFF_SCHEDULE_PATH, STAFF_SETTINGS_PATH } from './constants';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
import { SwitchWith404 } from '@widgets/PageNotFound/SwitchWith404';
import { Settings } from './settings';
import { Schedule } from './schedule';
import { Staff } from './staff';

import t from 'i18n';

export default () => (
	<SwitchWith404>
		<Route path={STAFF_PATH} component={withTitleAndLink(Staff, { title: t('staff.schedule') }) }/>
		<Route path={STAFF_SCHEDULE_PATH} component={withTitleAndLink(Schedule, { title: t('staff.schedule') })} />
		<Route path={STAFF_SETTINGS_PATH} component={withTitleAndLink(Settings, { title: t('settings') })} />
	</SwitchWith404>
);
