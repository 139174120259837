import { AutoAnimate } from '@smartplatform/consta/ui/AutoAnimate';
import { Text } from '@consta/uikit/Text';
import React from 'react';
import './List.scss';
import { t } from '../i18n';

/**
 * A component that displays a list of items with an option to show more or less.
 *
 * @param {Array<{ name: string }>} items - The items to be displayed in the list.
 * @param {number} [maxVisibleItems=3] - The maximum number of items to display initially.
 * @param {boolean} [showAllLabel] - The label for the "show all" toggle. Defaults to i18n translation.
 */

export const List = ({ items, maxVisibleItems = 3, showAllLabel }) => {
	const [showAll, setShowAll] = React.useState(false);

	const cancelEvents = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};
	const onShowAllClick = (e) => {
		cancelEvents(e);
		setShowAll(!showAll);
	};
	if (!items?.length) return;

	return (
		<div className='KanbanCard__List'>
			<AutoAnimate>
				{items.slice(0, showAll ? items.length : maxVisibleItems).map((item, index) => (
					<Text key={index} size='xs' view='secondary'>
						{index + 1}. {item.name}
					</Text>
				))}
			</AutoAnimate>
			{items.length > maxVisibleItems && (
				<Text
					size='xs'
					onClick={onShowAllClick}
					view='link'
					className='showAllToggle'
					draggable={false}
					onMouseDown={cancelEvents}
					onMouseUp={cancelEvents}
				>
					{showAll ? t('showLess') : showAllLabel || t('showAll')}
				</Text>
			)}
		</div>
	);
};
