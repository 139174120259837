import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { fioShort, relative } from 'client/tools';
import classNames from 'classnames';
import { Badge } from '@consta/uikit/Badge';
import { UserAvatar } from '@smartplatform/consta/ui/UserAvatar';
import { Text } from '@consta/uikit/Text';
import t from 'i18n';
import './log.scss';

@observer
export class Log extends Component {
	render() {
		const { className, instanceLog, actions, roles = [], content, controls } = this.props;

		return (
			<div className={classNames('log', className)}>
				<UserAvatar user={instanceLog.owner} onlyAvatar size='l' />

				<div className='what'>
					<div className='top'>
						<div className='subject-date'>
							{instanceLog.owner ? (
								<Text className='subject'>{fioShort(instanceLog.owner)}</Text>
							) : (
								<Text className='subject'>{t('userDeleted')}</Text>
							)}
							<Text view='ghost' size='s'>
								{relative(instanceLog.createdAt)}
							</Text>
						</div>
						<div className='actions'>
							{actions.length > 0 &&
								actions.map((action, i) => {
									return (
										<Text view='secondary' className='action' key={i}>
											<>{action.title} </>
											<Text view='primary' weight='bold' as='span'>
												{action.subject}
											</Text>
										</Text>
									);
								})}
						</div>

						{roles.length > 0 && (
							<div className='roles'>
								{roles.map((role, i) => (
									<Badge key={i} className='role' form='round' label={role} size='s' view='stroked' status='system' />
								))}
							</div>
						)}
						{controls}
					</div>
					{content}
				</div>
			</div>
		);
	}
}
