import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { DADATA_API_KEY, DADATA_API_URL } from 'components/contragents/constants';
import FormContext from '@smartplatform/ui/src/components/form/context';
import { AUTOCOMPLETE, AUTOCOMPLETE_BANK } from './constants';
import { AutoComplete } from '@consta/uikit/AutoComplete';
import t from 'i18n';
import './Suggestions.scss';

@observer
export class Suggestions extends React.Component {
	static contextType = FormContext;
	static propTypes = {
		search: PropTypes.oneOf(['party', 'bank']),
	};
	static defaultProps = {
		search: 'party',
	};
	@observable suggestions = [];
	@observable isLoading = false;
	constructor(props) {
		super(props);
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}
	onChange = (value) => {
		const { onChange } = this.props;
		onChange && onChange(value);
		this.doSearch(value);
	};
	onBlur = async (event) => {
		event.stopPropagation();
		const { handleBlur } = this.props;
		handleBlur && (await handleBlur(event.target.value));
	};
	onSelect = async (suggestion) => {
		if (!suggestion || !this.context?.record) return;
		const { onSuggestionClick, search } = this.props;
		onSuggestionClick && (await onSuggestionClick(suggestion));
		Object.keys(search === 'bank' ? AUTOCOMPLETE_BANK : AUTOCOMPLETE).forEach((property) => {
			this.context.record[property] = AUTOCOMPLETE[property](suggestion);
		});
		this.suggestions = [];
	};
	doSearch = async (value) => {
		this.isLoading = true;
		const response = await fetch(`${DADATA_API_URL}${this.props.search}`, {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', Accept: 'application/json', Authorization: 'Token ' + DADATA_API_KEY },
			body: JSON.stringify({ query: value }),
		});
		const { suggestions = [] } = await response.json();
		this.suggestions = suggestions.map(({ data }) => data);
		this.isLoading = false;
	};
	render() {
		const { value, search } = this.props;
		const { isLoading, onChange, suggestions, onSelect, onBlur } = this;

		return (
			<AutoComplete
				className='Suggestions'
				value={value}
				items={suggestions}
				onChange={onChange}
				getItemKey={(item) => item}
				onBlur={onBlur}
				getItemLabel={(item) => ''}
				isLoading={isLoading}
				searchFunction={() => true}
				renderItem={({ item, active, hovered, onClick, onMouseEnter, ref }) => (
					<div className='Suggestions__Item' onClick={() => onSelect(item)}>
						<div className='name-with-opf'>{item.name.short_with_opf || item.name.payment}</div>
						<div className='suggestion-footer'>
							<div>
								{search === 'bank' ? `${t('contragents.card.bik')}: ${item.bic}` : `${t('contragents.card.inn')}: ${item.inn}`} <br />
								{item.address?.value}
							</div>
						</div>
					</div>
				)}
			/>
		);
	}
}
