import format from 'date-fns/format';
import formatRelative from 'date-fns/formatRelative';
import store from 'client/store';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-GB';
const LOCALES = { ru, en };

export const relative = (date) => {
	return date
		? formatRelative(new Date(date), new Date(), { locale: LOCALES[store.model.user.language?.code || 'ru'], weekStartsOn: 1 })
		: null;
};

export const formatDate = (date, _format = 'dd.MM.yyyy') =>
	date ? format(new Date(date), _format, { locale: LOCALES[store.model.user.language?.code || 'ru'], weekStartsOn: 1 }) : '';

export const formatTime = (int, _format = 'dd.MM.yyyy HH:mm:ss') => formatDate(new Date(int), _format);

