import React from 'react';
import { Text } from '@consta/uikit/Text';
import './PositionTotal.scss';
import { t } from '../i18n';

/**
 * Component of total and position count
 * @param {number} total - total count
 * @param {number} positionCount - count of positions

 */

export const PositionTotal = ({ total, positionCount }) => {
	if (!total && !positionCount) return;

	return (
		<div className='KanbanCard__PositionTotal'>
			{total && (
				<Text size='xs' className='sum'>
					{t('sum')}: <Text weight='bold'>{total}</Text>
				</Text>
			)}
			{positionCount > 0 && (
				<Text size='xs' className='positionCount'>
					{t('positionCount')}: <Text weight='bold'>{positionCount}</Text>
				</Text>
			)}
		</div>
	);
};
