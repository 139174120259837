import React from 'react';
import Root from './Root';
import { STAFF_PATH, STAFF_SETTINGS_PATH, STAFF_SCHEDULE_PATH } from './constants';

import IconUsers from '@phosphor-icons/core/regular/users-three.svg';
import IconFolder from '@phosphor-icons/core/regular/folder-user.svg';
import IconSettings from '@phosphor-icons/core/regular/sliders-horizontal.svg';

import t from 'i18n';

export default {
	/** Заголовок модуля */
	title: t('staff.plural'),

	/** Метка для модуля deprecated|beta */
	type: 'beta',

	/** Короткий заголовок модуля */
	titleShort: t('staff.titleShort'),

	/** Код модуля на латинице, обязателен */
	code: 'staff',

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	menu: () => [
		{
			type: 'item',
			label: t('staff.plural'),
			path: STAFF_PATH,
			icon: () => <IconUsers size='m' />,
		},
		{
			type: 'item',
			label: t('staff.schedule'),
			path: STAFF_SCHEDULE_PATH,
			icon: () => <IconFolder size='m' />,
		},
		{
			type: 'item',
			label: t('settings'),
			path: STAFF_SETTINGS_PATH,
			icon: () => <IconSettings size='m' />,
		},
	],
};
