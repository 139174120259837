import React from 'react';
import { Button, LabelField, TruncateText } from 'components';
import { inject, observer } from 'mobx-react';
import { RecordSelect, Select } from '@smartplatform/ui';
import { CATEGORIES_ERP_ITEMS, NEW_CONTRAGENT } from '@pages/messages/dialogs/constants';
import t from 'i18n';
import { DocumentForm, OrderForm, TaskForm } from '@pages/messages/dialogs/single/category-erp';
import store from 'client/store';
import NewContragentPopup from 'components/new-contragent-popup/NewContragentPopup';
import { Section } from '@features/Section';

export const FORM_BY_MODEL = {
	ORDER: OrderForm,
	DOCUMENT: DocumentForm,
	TASK: TaskForm,
};

@inject('store')
@observer
export default class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	renderContragentValue = (record) => {
		return <TruncateText className='wrapper-contragent-name'>{record?.name}</TruncateText>;
	};

	render() {
		const {
			categoryERP,
			onChangeCategory,
			addRelationRecord,
			relationRecords,
			removeRelationRecord,
			dialog,
			onChangeContragent,
			contragent,
			createEmployee,
			showCreateContragent,
			onCloseContragentPopup,
			onSaveContragent,
		} = this.store;

		const appendItems = store.model.Contragent.INFO.WRITE
			? [{ label: t('order.contragent.new'), value: NEW_CONTRAGENT, className: 'new-client' }]
			: undefined;

		const disabledCreateEmployee =
			(dialog.employeeId && contragent?.id === dialog.employee.contragentId) || !contragent || contragent === NEW_CONTRAGENT;

		return (
			<>
				<Section title={t('dialog.employee')}>
					<div className='wrapper-new-employee'>
						<LabelField label={t('contragent.title')}>
							<RecordSelect
								model={store.model.Contragent}
								onChange={onChangeContragent}
								computed={this.renderContragentValue}
								placeholder={t('select')}
								className='with-new-record'
								appendItems={appendItems}
								value={contragent}
								itemsPerPage={8}
								filter={{ where: { contragentId: null } }}
							/>
						</LabelField>
						<Button onClick={createEmployee} disabled={disabledCreateEmployee}>
							{t('dialog.createEmployee')}
						</Button>
					</div>
				</Section>
				<Section title='ERP'>
					<div className='relation-records-controls'>
						<LabelField label={t('dialog.selectCategory')}>
							<Select items={CATEGORIES_ERP_ITEMS} value={categoryERP} onChange={onChangeCategory} isRequired />
						</LabelField>
						<Button onClick={addRelationRecord} disabled={!categoryERP}>
							{t('add')}
						</Button>
					</div>
				</Section>
				<div className='relation-records-content'>
					{relationRecords.map((record, index) => {
						const FormComponent = FORM_BY_MODEL[record.MODEL.INFO.name.toUpperCase()];
						return (
							<FormComponent
								record={record}
								dialog={dialog}
								key={index + Date.now()}
								onCancel={() => removeRelationRecord(index)}
							/>
						);
					})}
				</div>
				{showCreateContragent && <NewContragentPopup onSave={onSaveContragent} onClose={onCloseContragentPopup} />}
			</>
		);
	}
}

