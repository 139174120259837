export default {
	_AgGrid: {
		saveSettings: 'Save table settings',
		resetSettings: 'Reset table settings',
		deleteTemplate: 'Delete template',
		editTemplate: 'Edit name',
		floatingFilter: 'Filters under columns',
		templates: 'Templates',
		loadTemplate: 'Load template',
		rewriteTemplate: 'Rewrite template',
		templateLoaded: 'Template "{{template}}" loaded',
		templateRewrited: 'Template "{{template}}" rewrited',
		templateSaved: 'Template "{{oldName}}" name changed to "{{template}}"',
		templateCreated: 'Template "{{template}}" created',
		templateDeleted: 'Template "{{template}}" deleted',
		namePlaceholder: 'Type name',
		settings: 'Settings',
		leftSidebar: 'Sidebar on left side',
		theme: 'Theme',
		cancel: 'Cancel',
		save: 'Save',
		myTemplates: 'My templates',
		sharedTemplates: 'Shared templates',
		addMyTemplate: 'Add my template',
		addSharedTemplate: 'Add shared template',
	},
};

