import { Breadcrumbs } from '@consta/uikit/Breadcrumbs';
import { autorun, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import store from '@appStore';
import './HeaderBreadcrumbs.scss';
import { addResourceBundle } from './i18n';
import { getUrlMap } from './getUrlMap';
addResourceBundle();

@observer
export class HeaderBreadcrumbs extends React.Component {
	@observable items = [];
	constructor(props) {
		super(props);
		this.items = [];
		autorun(() => {
			try {
				// autorun запускается на каждое изменение store.route.path
				store.route.path;

				// _ management / projects / :projectId / tasks / :taskId
				const [_, moduleName, page1, page2, page3, page4] = store.route.path.split('/');

				let url = ``;
				// мапу каждый раз перевызываем чтобы получать переводы в соотв с текущим языком
				let map = getUrlMap();
				const items = [];

				const parsePage = (page) => {
					// продвигаемся по мапе и роуту ниже
					url = `${url}/${page}`;
					// если страничка это айдишник
					const isPageIsId = !isNaN(+page);

					// если мы на айдишнике и такой проперти есть - опускаемся в него, иначе опускаемся по названию страницы
					if (isPageIsId && map.id) {
						map = map.id;
					} else {
						map = map[page];
					}

					if (map) {
						let label = map.label;
						if (label && isPageIsId) {
							// если мы на айдишнике, то добавляем номер, например "Задача #12"
							label = label + ' #' + page;
						}

						// если есть метка, то добавляем в крошки
						label && items.push({ label, url });
						// если есть проп items , то опускаем мапу в него
						if (map.items) {
							map = map.items;
						}
					}
				};
				moduleName && parsePage(moduleName);
				page1 && map && parsePage(page1);
				page2 && map && parsePage(page2);
				page3 && map && parsePage(page3);
				page4 && map && parsePage(page4);
				this.items = items;
			} catch (e) {
				console.error(e);
			}
		});
	}

	onItemClick = (item) => {
		store.route.push({ path: item.url });
	};

	render() {
		return <Breadcrumbs fitMode='dropdown' items={this.items} getItemOnClick={this.onItemClick} className='HeaderBreadcrumbs' />;
	}
}
