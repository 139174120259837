import React, { createRef } from 'react';
import store from 'client/store';
import { Text } from '@consta/uikit/Text';
import { Desktop, NotPhone } from 'components';
import { observer } from 'mobx-react';
import { BackButton } from '@features/BackButton';
import { BillingButton } from '@features/BillingButton';
import { GlobarSearch } from './GlobalSearch';
import { OnlineUsers } from './OnlineUsers';
import { UserWithContextMenu } from './UserWithContextMenu';
import { Billing } from '@widgets/Billing';
import './Header.scss';
import { HeaderBreadcrumbs } from './HeaderBreadcrumbs/HeaderBreadcrumbs';

@observer
export class Header extends React.Component {
	ref = createRef(null);

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<header className='Header'>
				<Desktop>
					<HeaderBreadcrumbs />
				</Desktop>
				<NotPhone>
					<GlobarSearch className='Header-col' />
				</NotPhone>
				<div className='Header-col Header-col-end'>
					<Desktop>
						<OnlineUsers />
					</Desktop>
					<BillingButton inputRef={this.ref} />
					{typeof store.ui.showPopupBilling === 'boolean' && <Billing anchorRef={this.ref} />}
					<UserWithContextMenu />
				</div>
			</header>
		);
	}
}

