export default {
	Dictionary: {
		emptyModel: 'Модель не определена',
		notReadAccess: 'Нет доступа на чтение',
		addRecord: 'Добавить запись',
		editRecord: 'Редактировать запись',
		errorSave: 'Сохранение не удалось',
		errorDelete: 'Удаление не удалось',
	},
};
