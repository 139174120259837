import { DatePicker } from '@smartplatform/consta/ui/DatePicker';
import store from '@appStore';
import React from 'react';
import { endOfDay, startOfDay } from 'date-fns';
import t from 'i18n';

export class WorkHourDatePicker extends React.Component {
	startHour = store.workStartHour;
	endHour = store.workEndHour;

	getDisableDates = (_date) => {
		// Выключаем у выбранного дня нерабочие часы
		const date = this.props.value ? new Date(this.props.value) : new Date();
		const workStartTime = new Date(date.setHours(this.startHour));
		const workEndTime = new Date(date.setHours(this.endHour) + 1);

		return [
			[startOfDay(date), workStartTime],
			[workEndTime, endOfDay(date)],
		];
	};

	onChange = (date) => {
		// если даты нет, то ставится по дефолту
		const autoHour = this.props.autoHour === 'start' ? this.startHour : this.props.autoHour === 'end' ? this.endHour : null;
		date = !this.props.value && date && autoHour ? new Date(date.setHours(autoHour)) : date;
		this.props.onChange(date);
	};
	render() {
		const disableDates = this.getDisableDates(this.props.value);
		const { autoHour = 'start', startHour, endHour, ...restProps } = this.props;

		return (
			<DatePicker
				{...restProps}
				placeholder={this.props.placeholder || t('dateTimePickerPlaceholder')}
				disableDates={disableDates}
				type='date-time'
				format='dd.MM.yyyy HH:mm'
				onChange={this.onChange}
			/>
		);
	}
}
