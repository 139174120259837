import React from 'react';
import { Redirect } from 'react-router-dom';
import { STAFF_SETTINGS_DICTIONARIES_PATH, STAFF_SETTINGS_PATH } from '@pages/staff/constants';
import { PageNotFound } from '@widgets/PageNotFound';
import { DictionariesPage } from './DictionariesPage';
import BooksIcon from '@phosphor-icons/core/regular/books.svg';

import t from 'i18n';

export default () => [
	{
		path: STAFF_SETTINGS_PATH,
		children: <Redirect to={STAFF_SETTINGS_DICTIONARIES_PATH} />,
		exact: true,
	},
	{
		path: STAFF_SETTINGS_DICTIONARIES_PATH,
		label: t('dictionary.plural'),
		render: (props) => <DictionariesPage {...props} />,
		icon: BooksIcon,
	},
	{
		path: '*',
		component: PageNotFound,
	},
];
