import React from 'react';
import { Form as Form_ } from '@smartplatform/ui';
import { Actionbar } from './actionbar';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { addAlertNotification, addSuccessNotification } from '@smartplatform/consta/ui/Notifications';
import './formComponent.scss';
import { observer } from 'mobx-react';
import t from 'i18n';
import { lowerFirst } from 'lodash';

@observer
export class Form extends React.Component {
	static propTypes = {
		noSave: PropTypes.bool,
		noDelete: PropTypes.bool,
		noCancel: PropTypes.bool,
		saveTitle: PropTypes.string,
		deleteTitle: PropTypes.string,
		cancelTitle: PropTypes.string,
		onCancel: PropTypes.func,
		disableSave: PropTypes.bool,
		disableDelete: PropTypes.bool,
		className: PropTypes.string,
		checkIsDirty: PropTypes.bool,
	};

	static defaultProps = {
		showNotifications: true,
	};

	constructor(props) {
		super(props);
		this.formElementRef = React.createRef();
		this.showNotifications = props.showNotifications !== false; // true default
	}

	onError = (error) => {
		// дефолтное поведение
		let message = error.message;

		// Ошибки валидации возникают при срабатывании констрейнов
		// в details.codes лежит объект в формате { property : 'error code' }
		if (error.name === 'ValidationError' && error.details?.codes) {
			const model = lowerFirst(this.props.record.MODEL.INFO.name);

			message = Object.values(error.details.codes)
				.map((code) => t(`${model}.${code}`))
				.join(', ');
			// если в ошбике передлан код, то пытаемся через i18n показать его
		} else if (error.code) {
			message = t(`errorCodes.${error.code}`);
		}

		this.props.onError?.(error);
		this.showNotifications && message && addAlertNotification(message);
	};

	onSave = async (record) => {
		await this.props.onSave?.(record);
		this.showNotifications && addSuccessNotification(t('record.saved'));
	};

	onDelete = async (record) => {
		await this.props.onDelete?.(record);
		this.showNotifications && addAlertNotification(t('record.deleted'));
	};

	get isRequiredFieldsFilled() {
		const { PROPERTIES } = this.props.record.MODEL;
		const requiredFields = Object.keys(PROPERTIES).filter((key) => PROPERTIES[key].required === true);
		return !requiredFields.find((key) => !this.props.record[key] && this.props.record[key] !== 0);
	}

	render() {
		const { onError, onSave, onDelete } = this;

		let {
			children,
			noSave,
			noDelete,
			noClean,
			noCancel,
			saveTitle,
			deleteTitle,
			cancelTitle,
			cleanTitle,
			onCancel,
			onClean,
			disableSave,
			disableDelete,
			className,
			controls,
			showControls = true,
			isNew,
			checkRequiredFields = true,
			checkIsDirty = false,
			...formProps
		} = this.props;

		if (checkRequiredFields) {
			disableSave = disableSave || !this.isRequiredFieldsFilled;
		}

		if (checkIsDirty) {
			disableSave = disableSave || !this.props.record.isDirty;
		}

		const className_ = classNames(className, { 'no-form-submit': noCancel && noDelete && noSave });

		const controlsProps = {
			isNew,
			noSave,
			noDelete,
			noCancel,
			noClean,
			saveTitle,
			deleteTitle,
			cancelTitle,
			cleanTitle,
			onCancel,
			onClean,
			disableSave,
			disableDelete,
			controls,
			formElementRef: this.formElementRef,
		};

		return (
			<Form_
				noDelete
				noSave
				stay
				className={className_}
				{...formProps}
				controls={showControls && <Actionbar {...controlsProps} />}
				onError={onError}
				onSave={onSave}
				onDelete={onDelete}
			>
				<div ref={this.formElementRef}>{children}</div>
			</Form_>
		);
	}
}
