import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { TruncateText } from 'components';
import store from 'client/store';
import t from 'i18n';
import { NumberInput } from '@smartplatform/consta/ui/NumberInput';

@inject('productStore')
@observer
export default class VariantPrice extends React.Component {
	@observable rangePrice = null;
	@observable changed = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { range, nomenclature } = this.props;
		const prices = await nomenclature.prices();
		this.rangePrice = prices.find((price) => price.rangeId === range.id);
		if (this.rangePrice) {
			this.origPrice = this.rangePrice.price;
		} else {
			this.rangePrice = new store.model.ProductVariantPrice();

			this.rangePrice.rangeId = range.id;
			this.rangePrice.nomenclatureId = nomenclature.id;
			await this.rangePrice.save();
			this.origPrice = null;
		}
	};

	onChange = (value) => {
		this.rangePrice.price = value;
		this.props.onChange(this.rangePrice);

		if (this.origPrice !== value) this.changed = true;
	};

	save = async () => {
		this.origPrice = this.rangePrice.price;
		if (this.rangePrice.price) {
			this.changed = false;
		} else {
			await this.delete();
		}
	};

	onBlur = async () => {
		await this.save();
	};

	delete = async () => {
		await this.rangePrice.delete();
		this.rangePrice = null;
		this.init();
		this.changed = false;
	};

	render() {
		const { range } = this.props;

		if (!this.rangePrice) return null;

		return (
			<>
				<TruncateText className='range'>
					<>
						{t('from')} {range.amountFrom || '1'}&nbsp;
						{range.amountTo && (
							<>
								{t('to')} {range.amountTo || <>&infin;</>}
							</>
						)}
					</>
				</TruncateText>
				<div className='value'>
					<NumberInput onChange={this.onChange} positiveOnly value={this.rangePrice.price} onBlur={this.onBlur} />
				</div>
			</>
		);
	}
}
