import React from 'react';
import { observer } from 'mobx-react';
import { FieldCaption, FieldClearButton, FieldLabel } from '@consta/uikit/FieldComponents';
import { BlockPicker } from 'react-color';
import { Popover } from '@consta/uikit/Popover';
import { observable } from 'mobx';
import { Button } from '@consta/uikit/Button';
import './ColorPicker.scss';
import classNames from 'classnames';

const DEFAULT_BACKGROUND =
	'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=) left center';

/*
 * label, icon, size, required, iconRef - пропсы для вывода label (consta)
 * direction - положение Popover (consta)
 * caption, status - пропсы для вывода подписи к полю (consta)
 * value, onChange, withClearButton - собственные пропсы
 * restProps - все остальные пропсы, передаются в BlockPicker
 * disableInput - флаг, что нужно убрать инпут
 * defaultColor - цвет по умолчанию (в HEX)
 * */
@observer
export class ColorPicker extends React.Component {
	@observable isShow = false;

	static defaultProps = {
		disabled: false,
		withClearButton: true,
		disableInput: true,
	};

	ref = React.createRef();

	onChange = (color) => {
		this.props.onChange && this.props.onChange(color.hex);
	};

	reset = () => {
		this.props.onChange && this.props.onChange(null);
	};

	closePopover = () => (this.isShow = false);
	togglePopover = () => (this.isShow = !this.isShow);

	render() {
		const {
			label,
			icon,
			size,
			required,
			iconRef,
			direction,
			caption,
			status,
			value,
			onChange,
			withClearButton,
			className,
			defaultColor,
			disableInput,
			...restProps
		} = this.props;

		const _value = value || defaultColor || '#000';

		const disableInputClassName = !disableInput && 'ColorPicker-popover_disabledInput';

		return (
			<div className={classNames('ColorPicker', className)}>
				{label && (
					<FieldLabel icon={icon} size={size} required={required} iconRef={iconRef} className='ColorPicker-label'>
						{label}
					</FieldLabel>
				)}
				<div className='ColorPicker-input'>
					<Button
						className='ColorPicker-btn'
						view='clear'
						ref={this.ref}
						onClick={this.togglePopover}
						style={{ background: value || DEFAULT_BACKGROUND }}
					/>
					{this.isShow && (
						<Popover
							anchorRef={this.ref}
							onClickOutside={this.closePopover}
							direction={direction}
							offset={8}
							className={classNames('ColorPicker-popover', disableInputClassName)}
						>
							<BlockPicker {...restProps} color={_value} onChange={this.onChange} triangle='hide' />
						</Popover>
					)}
					{value && withClearButton && <FieldClearButton onClick={this.reset} />}
				</div>
				{caption && (
					<FieldCaption className='ColorPicker-capture' status={status}>
						{caption}
					</FieldCaption>
				)}
			</div>
		);
	}
}
