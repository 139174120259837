import React from 'react';
import { observer } from 'mobx-react';
import { Switch } from '@smartplatform/consta/ui/Switch';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Button } from '@consta/uikit/Button';
import { Modal } from '@consta/uikit/Modal';
import { RecordSelect } from '@smartplatform/consta/ui/RecordSelect';
import { DatePicker } from '@smartplatform/consta/ui/DatePicker';
import { TextField } from '@consta/uikit/TextField';
import { Text } from '@consta/uikit/Text';

import _store from '@appStore';
import t from 'i18n';

@observer
export class DocumentEdit extends React.Component {
	render() {
		const { store } = this.props;
		const labelSave = store.document && store.document.id ? t('staff.save') : t('create');

		return (
			<>
				{store.document && (
					<Modal
						isOpen={true}
						className='staff-documents-popup staff-documents-popup-create'
						onClickOutside={store.onClose}
						onEsc={store.onClose}
					>
						<div className='staff-documents-content'>
							<Text size='2xl' weight='bold'>
								{t('staff.addDocument')}
							</Text>
							<Grid gap='xl' cols={2}>
								<GridItem>
									<RecordSelect
										model={_store.model.ContragentPersonalDocumentType}
										label={t('staff.documentType')}
										placeholder={t('select')}
										property='name'
										onChange={store.onChangeDocument('type')}
										value={store.document.type}
									/>
								</GridItem>
							</Grid>
							<Grid gap='xl' cols={3}>
								<GridItem>
									<TextField
										value={store.document.series}
										type='text'
										label={t('staff.series')}
										onChange={store.onChangeDocument('series')}
									/>
								</GridItem>
								<GridItem>
									<TextField
										value={store.document.number}
										type='text'
										label={t('staff.number')}
										onChange={store.onChangeDocument('number')}
									/>
								</GridItem>
							</Grid>
							<Grid gap='xl'>
								<GridItem>
									<TextField
										value={store.document.issuedByWhom}
										type='text'
										label={t('staff.issuedByWhom')}
										onChange={store.onChangeDocument('issuedByWhom')}
									/>
								</GridItem>
							</Grid>
							<Grid gap='xl'>
								<GridItem>
									<TextField
										value={store.document.placeOfBirth}
										type='text'
										label={t('staff.placeOfBirth')}
										onChange={store.onChangeDocument('placeOfBirth')}
									/>
								</GridItem>
							</Grid>
							<Grid gap='xl' cols={3}>
								<GridItem>
									<TextField
										value={store.document.departmentCode}
										type='text'
										label={t('staff.departmentCode')}
										onChange={store.onChangeDocument('departmentCode')}
									/>
								</GridItem>
							</Grid>
							<Grid gap='xl' cols={3}>
								<GridItem>
									<DatePicker
										format='dd.MM.yyyy'
										value={store.document.dateOfIssue ? new Date(store.document.dateOfIssue) : null}
										label={t('staff.dateOfIssue')}
										onChange={store.onChangeDocument('dateOfIssue')}
									/>
								</GridItem>
								<GridItem>
									<DatePicker
										format='dd.MM.yyyy'
										value={store.document.expirationDate ? new Date(store.document.expirationDate) : null}
										label={t('staff.expirationDate')}
										onChange={store.onChangeDocument('expirationDate')}
									/>
								</GridItem>
							</Grid>
							<Grid gap='xl' cols={3}>
								<GridItem col={2}>
									<DatePicker
										format='dd.MM.yyyy'
										value={
											store.document.informationDocumentValidAt
												? new Date(store.document.informationDocumentValidAt)
												: null
										}
										label={t('staff.informationDocumentValidAt')}
										onChange={store.onChangeDocument('informationDocumentValidAt')}
									/>
								</GridItem>
							</Grid>
							<Switch
								value={store.document.isIdentityDocument}
								className='toggle-copy-address'
								onChange={store.onChangeDocument('isIdentityDocument')}
								label={t('staff.isIdentityDocument')}
							/>
						</div>
						<div className='staff-documents-footer'>
							<Button label={labelSave} onClick={store.saveDocument} />
							<Button view='secondary' label={t('cancel')} onClick={store.onCloseEdit} />
						</div>
					</Modal>
				)}
			</>
		);
	}
}
