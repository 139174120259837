import { Text } from '@consta/uikit/Text';
import { ProgressLine } from '@consta/uikit/ProgressLine';
import React from 'react';
import './Progress.scss';

/**
 * Progress bar with steps count.
 *
 * @param {number}steps Count of steps.
 * @param {number} [done=0] Count of done steps.
 */

export const Progress = ({ steps, done = 0 }) => {
	if (!steps) return null;

	steps = new Array(steps).fill(null);

	return (
		<div className='KanbanCard__Progress'>
			<Text view='link' size='xs'>
				{done}/{steps.length}
			</Text>
			<ProgressLine value={done - 1} steps={steps} withLabel={false} getItemLabel={() => ''} />
		</div>
	);
};
