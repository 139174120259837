import React from 'react';
import appStore from 'client/store';
import { observer } from 'mobx-react';
import './settings.scss';
import { withTranslation } from 'react-i18next';

@withTranslation('components', { keyPrefix: '_AgGrid' })
@observer
export class SettingsToolPanel extends React.Component {
	onChange = (prop) => (value) => {
		appStore.local.agGrid[prop] = value;
		const { api } = this.props.gridRef.current;
		if (prop === 'leftSidebar') {
			api.setSideBarPosition(value ? 'left' : 'right');
		} else if (prop === 'floatingFilter') {
			const columnDefs = api.getColumnState();

			// Обновляем свойство floatingFilter для всех столбцов
			columnDefs.forEach((column) => {
				column.floatingFilter = value;
			});

			// Устанавливаем новую конфигурацию столбцов с обновленными floatingFilter
			api.applyColumnState({ state: columnDefs, applyOrder: true });
		}
	};

	render() {
		const agGridSettings = appStore.local.agGrid;
		const t = this.props.t;
		return (
			<div className='settings-tool-panel'>
				{['leftSidebar', 'floatingFilter'].map((label) => (
					<label className={label} key={label}>
						<input
							type='checkbox'
							label={t(label)}
							checked={agGridSettings[label]}
							onChange={(e) => this.onChange(label)(e.target.checked)}
						/>
						{t(label)}
					</label>
				))}
			</div>
		);
	}
}

