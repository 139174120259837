import management from './management';
import sales from './sales';
import finance from './finance';
import storehouse from './storehouse';
import manufacture from './manufacture';
import documents from './documents';
import admin from './admin';
import contragents from './contragents';
import staff from './staff';
import messages from './messages';

// порядок ключей обеспечит порядок в сайдбаре
const modules = { management, sales, messages, finance, storehouse, manufacture, documents, staff, contragents, admin };

export default modules;

