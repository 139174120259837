import classnames from 'classnames';
import { RecordSelect } from '@smartplatform/consta/ui/RecordSelect';
import React from 'react';
import { renderStatus } from './renderStatus';
import { observer } from 'mobx-react';

// вспомогательный компонент заваязанный на Status
@observer
export class StatusSelect extends React.Component {
	constructor(props) {
		super(props);
		const size = this.props.size;
		if (props.getItemLabel) {
			this.getItemLabel = (item) => getItemLabel(item, size);
		} else {
			this.getItemLabel = (item) => renderStatus(item, { size });
		}

		if (props.renderValue) {
			this.renderValue = (item) => props.renderValue(item, size);
		} else {
			this.renderValue = (item) => renderStatus(item, { size, truncate: true });
		}
	}

	render() {
		let { className, size = 'm', ...restComboboxProps } = this.props;
		const { getItemLabel, renderValue } = this;
		className = classnames('StatusSelect', className);
		return (
			<RecordSelect {...restComboboxProps} size={size} renderValue={renderValue} getItemLabel={getItemLabel} className={className} />
		);
	}
}
