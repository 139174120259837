import { t } from './i18n';

/**
 * Функция возвращает мапу крошек для HeaderBreadcrumbs
 *
 * Мапа описывает какие крошки будут отображаться. Каждый проперти описывает какую-то крошку. Если крошка имеет label, то она отображается
 * первый уровень - уровень модуля
 * второй и далее - страницы. Если на странице есть items, то мы будем опускаться ниже, чтобы добавить новые крошки
 * крошка с пропом id - это крошка с айдишником. Некое исключение на точное соотвествие
 *
 * @return {Object} Мапа крошек
 */
export const getUrlMap = () => ({
	management: {
		dashboard: { label: t('main') },
		projects: {
			label: t('projects'),
			items: {
				id: {
					label: t('project'),
					items: {
						tasks: {
							items: {
								id: { label: t('task') },
								create: { label: t('newTask') },
							},
						},
					},
				},
				create: { label: t('newProject') },
			},
		},
		tasks: {
			label: t('tasks'),
			items: { id: { label: t('task') }, create: { label: t('newTask') } },
		},
		settings: { label: t('managementSettings') },
	},
	profile: { label: t('profile') },
	test: { label: t('test') },
	sales: {
		dashboard: { label: t('analytics') },
		orders: {
			label: t('orders'),
			items: { id: { label: t('order') }, create: { label: t('newOrder') } },
		},
		catalog: { label: t('catalog') },
		reports: { label: t('reports') },
		settings: { label: t('salesSettings') },
	},
	documents: {
		documents: {
			label: t('documents'),
			items: { id: { label: t('document') }, create: { label: t('newDocument') } },
		},
		settings: { label: t('documentsSettings') },
	},
	manufacture: {
		dashboard: { label: t('analytics') },
		'production-orders': {
			label: t('productionOrders'),
			items: { id: { label: t('productionOrder') }, create: { label: t('newProductionOrder') } },
		},
		workshops: {
			label: t('workshops'),
			items: { id: { label: t('workshop') }, create: { label: t('newWorkshop') } },
		},
		equipments: {
			label: t('equipments'),
			items: { id: { label: t('equipment') }, create: { label: t('newEquipment') } },
		},
		processes: {
			label: t('processes'),
			items: { id: { label: t('process') }, create: { label: t('newProcess') } },
		},
		materials: {
			label: t('materials'),
			items: { id: { label: t('material') }, create: { label: t('newMaterial') } },
		},
		'tech-maps': {
			label: t('techMaps'),
			items: { id: { label: t('techMap') }, create: { label: t('newTechMap') } },
		},
		reports: { label: t('reports') },
		settings: { label: t('manufactureSettings') },
	},
	messages: {
		dialogs: {
			label: t('dialogs'),
			items: { id: { label: t('dialog') }, create: { label: t('newDialog') } },
		},
		settings: { label: t('messagesSettings') },
	},
	storehouse: {
		nomenclatures: {
			label: t('nomenclatures'),
			items: { id: { label: t('nomenclature') }, create: { label: t('newNomenclature') } },
		},
		nomenclatureMovements: {
			label: t('nomenclatureMovements'),
			items: {
				id: { label: t('nomenclatureMovement') },
				create: { label: t('newNomenclatureMovement') },
			},
		},
		warehouses: {
			label: t('warehouses'),
			items: {
				id: { label: t('warehouse') },
				create: { label: t('newWarehouse') },
			},
		},
		reports: { label: t('reports') },
		settings: { label: t('storehouseSettings') },
	},
	contragents: {
		contragents: {
			label: t('contragents'),
			items: { id: { label: t('contragent') }, create: { label: t('newContragent') } },
		},
		settings: { label: t('contragentsSettings') },
	},
	staff: {
		list: {
			label: t('staff'),
			items: { id: { label: t('staffMember') }, create: { label: t('newStaffMember') } },
		},
		schedule: { label: t('staffSchedule') },
		settings: { label: t('staffSettings') },
	},
	admin: {
		users: { label: t('users'), items: { id: { label: t('user') }, create: { label: t('newUser') } } },
		roles: { label: t('roles'), items: { id: { label: t('role') }, create: { label: t('newRole') } } },
		settings: { label: t('adminSettings') },
	},
	finance: {
		reports: { label: t('reports') },
		settings: { label: t('financeSettings') },
		analytics: { label: t('analytics') },
		mycompanies: { label: t('myCompanies') },
		operations: {
			label: t('operations'),
			items: { id: { label: t('operation') }, create: { label: t('newOperation') } },
		},
	},
});

