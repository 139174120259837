import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { RouteTabs } from '@smartplatform/consta/ui/RouteTabs';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';

import { tabs } from './tabs';
import store from 'client/store';
import './StaffEdit.scss';

@observer
export class StaffEdit extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	title = '';

	constructor(props) {
		super(props);
		this.id = parseInt(this.props.match.params.id);
		this.init();
	}

	init = async () => {
		this.isLoading = true;

		if (this.id) {
			this.record = await store.model[this.props.modelName].findById(this.id, {
				include: ['type', 'personalDocuments', 'country'],
			});
			this.title = this.record.personName;
		} else {
			this.record = new store.model[this.props.modelName]();
		}
		this.tabs = tabs(this.record);
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return <Loader />;

		return (
			<div className='staff-single'>
				<div className='toolbar-staff'>
					<Text size='m' weight='bold'>
						{this.title}
					</Text>
				</div>
				<div className='staff-single-content'>
					<RouteTabs items={this.tabs} linePosition='left' params={{ contragentId: this.id }} />
				</div>
			</div>
		);
	}
}
