import React from 'react';
import { observer, Provider } from 'mobx-react';
import { observable } from 'mobx';
import { Field, Row, RelationSelect, RecordSelect } from '@smartplatform/ui';
import { Form } from 'components';
import { addAlertNotification } from '@smartplatform/consta/ui/Notifications';
import Nomenclatures from './nomenclatures/Nomenclatures';
import ProductStore from './store';
import store from 'client/store';
import qs from 'qs';
import t from 'i18n';
import { CATALOG_PATH } from '@pages/sales/constants';
import './product.scss';
import { Sidebar } from './sidebar';
import salesStore from '@pages/sales/salesStore';
import { ImageUploader } from '@features/ImageUploader';
import { Section } from '@features/Section';

@observer
export class Product extends React.Component {
	@observable product = null;

	constructor(props) {
		super(props);
		const productId = props.match.params.productId;
		this.categoryParentId = store.route?.params?.categoryParentId || null;
		this.productStore = new ProductStore(productId, this.categoryParentId);

		const { search } = store.route._location();
		const { categoryId } = qs.parse(search);

		if (categoryId) {
			this.productStore.product.categoryId = categoryId;
		}
	}

	onSave = async (record) => {
		if (store.route?.params?.techMapId) {
			const techMap = await store.model.TechMap.findById(store.route?.params?.techMapId);
			techMap.productId = record.id;
			await techMap.save();
		}

		if (this.productStore.productId) {
			store.route.push({
				path: `${CATALOG_PATH}`,
				params: record.categoryId ? { categoryParentId: record.categoryId } : undefined,
			});
		} else {
			this.productStore.productId = record.id;
			store.route.push({
				path: `${CATALOG_PATH}/product/${record.id}`,
				params: undefined,
			});
		}
	};

	onCancel = () => {
		store.route.push({ path: CATALOG_PATH });
	};

	onDelete = () => {
		store.route.push({ path: CATALOG_PATH });
	};

	computed = (record) => {
		const { categories } = this.productStore;
		return categories[record.id]?.name;
	};

	getProductTypeName = (type) => {
		return <div>{t(`product.codes.${type.code}`)}</div>;
	};

	onError = (error) => addAlertNotification(error);

	render() {
		if (this.productStore.isLoading || !salesStore.isInitialized) return null;

		const { product } = this.productStore;
		const id = product.id;
		let validateField = ['categoryId', 'name', 'typeId'];
		let disableSave = !!validateField.find((prop) => !product[prop]);

		return (
			<Provider productStore={this.productStore}>
				<div className='product-page'>
					<div className='product-information-variants'>
						<Section title={t('product.info')}>
							<Form
								record={product}
								onSave={this.onSave}
								onDelete={this.onDelete}
								onCancel={this.onCancel}
								cancelTitle={t('catalog.goBack')}
								stay
								disableSave={disableSave}
							>
								<div className='main-information'>
									<div className='left'>
										<Field property='name' label={t('product.name')} isRequired />
										<Field relation='category' label={t('product.category')} computed={this.computed} isRequired>
											<RecordSelect isRequired model={store.model.ProductCategory} />
										</Field>
										<div className='type-measure'>
											<Row>
												<Field
													relation='type'
													computed={this.getProductTypeName}
													label={t('product.type')}
													isRequired
												>
													<RelationSelect isRequired />
												</Field>
												<Field relation='measure' property='name' label={t('measure.title')}>
													<RelationSelect filter={{ order: 'id' }} />
												</Field>
											</Row>
										</div>
										{/* <div className='tech-road-map'>
											<TechMaps techMapForCreate={store.route?.params?.techMapId} />
										</div> */}
									</div>
									<div className='right'>
										<Field label={t('image')} property='image'>
											<ImageUploader
												maxSize={parseInt(salesStore.catalogMaxSizeImage) * 1024 * 1024}
												onError={this.onError}
											/>
										</Field>
									</div>
								</div>
							</Form>
						</Section>
						{id && <Nomenclatures {...this.props} />}
					</div>
					<Sidebar id={id} />
				</div>
			</Provider>
		);
	}
}

