import { observable } from 'mobx';
import { DADATA_API_KEY, DADATA_API_URL } from 'components/contragents/constants';

import store from 'client/store';

export default class StaffStore {
	@observable record = null;
	@observable suggestions = [];
	@observable personalDocument = {};
	@observable document = null;
	@observable documents = {};
	@observable types = [];
	@observable countries = [];
	@observable isViewDocuments = false;
	@observable isAddressFactCopy = false;
	@observable isAddressInfoCopy = false;

	constructor({ record }) {
		this.record = record;
		this.init();
	}

	init = async () => {
		this.documents = await this.record.personalDocuments();
		const personalDocument = this.documents.find((d) => d.isIdentityDocument);
		if (personalDocument) {
			this.personalDocument = { ...personalDocument, type: await personalDocument.type };
		}
	};

	getAddresses = async (query) => {
		const body = {
			query: query,
			count: 10,
			from_bound: { value: 'settlement' },
			to_bound: { value: 'house' },
		};
		let res = await fetch(`${DADATA_API_URL}address`, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Token ${DADATA_API_KEY}`,
			},
		});
		const json = await res.json();
		return json.suggestions.map((s) => {
			return {
				label: s.value,
				id: s.data.kladr_id,
			};
		});
	};

	onToggleCopyAddressFact = (value) => {
		this.isAddressFactCopy = value;
		if (value) {
			this.record.addressFact = this.record.addressRegister;
		} else {
			this.record.addressFact = null;
		}
	};

	onToggleCopyAddressInfo = (value) => {
		this.isAddressInfoCopy = value;
		if (value) {
			this.record.addressInfo = this.record.addressFact;
		} else {
			this.record.addressInfo = null;
		}
	};

	onChange = (prop, isAddress) => async (value) => {
		this.record[prop] = value;
		if (isAddress) {
			this.suggestions = await this.getAddresses(value);
		}
	};

	onChangeDocument = (prop) => (value) => {
		this.document[prop] = value;
	};

	back = () => store.route.push({ path: `/staff/list` });

	openDocuments = () => (this.isViewDocuments = true);

	onClose = () => {
		this.isViewDocuments = false;
		this.onCloseEdit();
	};

	onCloseEdit = () => {
		this.document = null;
	};

	createDocument = () => {
		this.document = new store.model.ContragentPersonalDocument({
			contragentId: this.record.id,
		});
	};

	editDocument = async (record) => {
		this.document = await store.model.ContragentPersonalDocument.findById(record.id);
	};

	saveDocument = async () => {
		if (!this.document.id) {
			this.documents.push(this.document);
		}
		await this.document.save();

		// Обсудить, убирает активность у всех документов
		if (this.document.isIdentityDocument) {
			this.documents = await this.record.personalDocuments();
			this.documents.map(async (d) => {
				if (this.document.id !== d.id) {
					d.isIdentityDocument = false;
					await d.save();
				}
			});
		}

		await this.init();
		this.onClose();
	};
}
