import { observable } from 'mobx';
import { set } from 'date-fns';

export default class UI {
	@observable menu = false;
	@observable activeSubMenu = null;
	@observable kanban = false;
	@observable showFeedbackPopup = false;
	@observable showPopupBilling = null;
}

