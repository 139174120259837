import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import NomenclatureFeatureValue from './NomenclatureFeatureValue';
import VariantPrices from './prices/VariantPrices';
import { Button, DeleteButton } from 'components';
import { addAlertNotification } from '@smartplatform/consta/ui/Notifications';
import { Field, Form } from '@smartplatform/ui';
import './nomenclature.scss';
import t from 'i18n';
import store from 'client/store';
import { debounce, upperFirst } from 'lodash';
import salesStore from '@pages/sales/salesStore';
import { ImageUploader } from '@features/ImageUploader';
import { TextField } from '@consta/uikit/TextField';
import { Grid } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';

@inject('productStore')
@observer
export default class NomenclatureCard extends React.Component {
	@observable isEdit = false;
	@observable imagePopup = false;
	@observable isNew = false;
	@observable isChangeName = false;
	@observable isChangeArticle = false;
	@observable errorName = null;
	@observable errorArticle = null;

	constructor(props) {
		super(props);
		this.savedValue = new Map();
		this.isNew = this.props.nomenclature.id === undefined;
		this.save = debounce(this.save, 500, { leading: false, trailing: true });
	}

	save = async () => {
		const { productStore, nomenclature } = this.props;
		this.errorName = null;
		this.errorArticle = null;
		for await (const [_, value] of this.savedValue) {
			value && value.save();
		}

		const isDuplicateName = (
			await store.model.Nomenclature.find({
				where: !!nomenclature.id ? { name: nomenclature.name, id: { neq: nomenclature.id } } : { name: nomenclature.name },
				fields: ['id'],
			})
		).length;

		const isDuplicateArticle = nomenclature.article
			? (
					await store.model.Nomenclature.find({
						where: !!nomenclature.id
							? { article: nomenclature.article, id: { neq: nomenclature.id } }
							: { article: nomenclature.article },
						fields: ['id'],
					})
				).length
			: false;

		if (!isDuplicateName && !isDuplicateArticle) {
			await this.props.nomenclature.save();
			await productStore.loadVariants();
			this.errorName = null;
			this.errorArticle = null;
			this.isNew = false;
		} else if (isDuplicateName) {
			this.errorName = t('nomenclature.isExistsName');
		} else if (isDuplicateArticle) {
			this.errorArticle = t('nomenclature.isExistsArticle');
		}

		this.isEdit = false;
	};

	delete = async () => {
		const { nomenclature, productStore } = this.props;
		if (!this.isNew) {
			nomenclature.product = null;
			await nomenclature.save();
		}
		await productStore.loadVariants();
	};

	onChange = (value) => {
		if (this.isNew) {
			this.savedValue.set(value.id, value);
		} else {
			value.save();
		}
	};

	onChangeImage = async (file, nomenclature) => {
		await nomenclature.save();
		await nomenclature.uploadFile('image', file);
	};

	onChangeNomenclatureProp = (prop) => (value) => {
		this.props.nomenclature[prop] = value;
		this[`isChange${upperFirst(prop)}`] = true;
		if (!this.isNew) {
			this.save();
		}
	};

	onError = (error) => addAlertNotification(error);

	render() {
		const { nomenclature, productStore, number } = this.props;
		const { nomenclaturesFeatures } = productStore;

		return (
			<div className='product-variant'>
				<ImageUploader
					onChange={(file) => this.onChangeImage(file, nomenclature)}
					record={nomenclature}
					property='image'
					maxSize={parseInt(salesStore.catalogMaxSizeImage) * 1024 * 1024}
					onError={this.onError}
				/>
				<div className='right-nomenclature-card'>
					<Grid gap='m' cols={2}>
						<TextField
							onChange={this.onChangeNomenclatureProp('name')}
							value={nomenclature.name}
							label={t('name')}
							caption={this.errorName}
							status={this.errorName && 'alert'}
							required
						/>
						<TextField
							onChange={this.onChangeNomenclatureProp('article')}
							value={nomenclature.article}
							label={t('article')}
							caption={this.errorArticle}
							status={this.errorArticle && 'alert'}
							required
						/>
					</Grid>
					{!!nomenclaturesFeatures.length && !this.isNew && (
						<div className='features'>
							<Text weight='bold' size='l'>
								{t('feature.plural')}
							</Text>
							{nomenclaturesFeatures.map((variantFeature) => (
								<div key={variantFeature.id} className='feature-field'>
									{/*<label>{variantFeature.feature.name}</label>*/}
									<NomenclatureFeatureValue
										nomenclature={nomenclature}
										feature={variantFeature.feature}
										onChange={this.onChange}
									/>
								</div>
							))}
						</div>
					)}
					{!this.isNew && <VariantPrices nomenclature={nomenclature} onChange={this.onChange} />}
					<div className='actions'>
						<DeleteButton
							onConfirm={this.delete}
							content={t('product.confirmDeleteVariant')}
							className='delete-variant'
						></DeleteButton>
						{(this.isNew || this.isEdit) && (
							<Button onClick={this.save} disabled={!nomenclature.name}>
								{t('save')}
							</Button>
						)}
					</div>
				</div>
			</div>
		);
	}
}
