import React from 'react';
import { addAlertNotification, addSuccessNotification } from '@smartplatform/consta/ui/Notifications';
import './AgGridTemplatesPanel.scss';
import appStore from '@appStore';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { AutoAnimate } from '@smartplatform/consta/ui/AutoAnimate';
import { Loader } from '@consta/uikit/Loader';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import { ContextMenuButton } from '@smartplatform/consta/ui/ContextMenuButton';
import IconTrash from '@phosphor-icons/core/regular/trash.svg';
import IconEdit from '@phosphor-icons/core/regular/pencil-simple.svg';
import { FieldGroup } from '@consta/uikit/FieldGroup';
import IconCancel from '@phosphor-icons/core/regular/prohibit.svg';
import IconCheck from '@phosphor-icons/core/regular/check.svg';
import IconAdd from '@phosphor-icons/core/regular/plus.svg';
import IconSwap from '@phosphor-icons/core/regular/swap.svg';
import { partition } from 'lodash';
import { t } from '../../i18n';

@observer
export class AgGridTemplatesPanel extends React.Component {
	@observable editRecord = null;
	@observable userTemplates = [];
	@observable sharedTemplates = [];
	@observable openIndex = null;
	@observable isInitialized = false;
	@observable isAdding = false;
	withEditAccess = false;
	constructor(props) {
		super(props);
		this.gridRef = props.gridRef;
		this.withEditAccess = props.withEditAccess;
		this.init();
		this.ref = React.createRef();
		this.t = props.t;
	}

	init = async () => {
		const templates = await appStore.model.AgGridTemplate.find({
			where: { tableCode: this.props.code, or: [{ userId: null }, { userId: appStore.model.user.id }] },
		});

		const [userTemplates, sharedTemplates] = partition(templates, (template) => template.userId === appStore.model.user.id);
		this.userTemplates = userTemplates;
		this.sharedTemplates = sharedTemplates;
		this.isInitialized = true;
	};

	onAddRecord = (targetArray) => () => {
		const newRecord = new appStore.model.AgGridTemplate({
			tableCode: this.props.code,
			userId: targetArray === 'sharedTemplates' ? null : appStore.model.user.id,
		});
		this[targetArray].push(newRecord);
		this.editRecord = newRecord;
	};

	onCancelEdit = (template, targetArray) => {
		if (!this.editRecord.id) {
			this[targetArray].pop();
		}
		if (template) {
			template.name = this.prevName;
		}

		this.editRecord = null;
		this.prevName = null;
	};

	onEditRecord = (template, e) => {
		this.prevName = template.name;
		this.editRecord = template;
	};

	onSaveRecord = async (template, e) => {
		const isNew = !template.id;
		if (isNew) {
			const state = this.gridRef.current.api.getColumnState();
			const isPivotMode = this.gridRef.current.api.isPivotMode();
			const filterModel = this.gridRef.current.api.getFilterModel();
			template.template = { state, isPivotMode, filterModel };
		} else {
		}
		if (this.prevName !== template.name) {
			await template.save();
			const message = isNew ? 'templateCreated' : 'templateSaved';
			addSuccessNotification(t(message, { template: template.name, oldName: this.prevName }));
		}

		this.editRecord = null;
		this.prevName = null;
	};

	onNameChange = (template, value) => (template.name = value);

	onDeleteRecord = async (template, index, targetArray) => {
		const name = template.name;
		await template.delete();
		this[targetArray].splice(index, 1);
		addAlertNotification(t('templateDeleted', { template: name }));
		this.onCancelEdit();
	};

	onLoadTemplate = (template) => {
		const { isPivotMode, state, filterModel } = template.template;
		this.gridRef.current.api.applyColumnState({ state, applyOrder: true });
		this.gridRef.current.api.setGridOption('pivotMode', isPivotMode);
		this.gridRef.current.api.setFilterModel(filterModel);
	};

	onRewriteTemplate = async (template, e) => {
		const state = this.gridRef.current.api.getColumnState();
		const isPivotMode = this.gridRef.current.api.isPivotMode();
		const filterModel = this.gridRef.current.api.getFilterModel();
		template.template = { state, isPivotMode, filterModel };
		await template.save();
		addSuccessNotification(t('templateRewrited', { template: template.name }));
	};

	render() {
		const {
			onAddRecord,
			onSaveRecord,
			onNameChange,
			onEditRecord,
			userTemplates,
			sharedTemplates,
			onCancelEdit,
			onLoadTemplate,
			onRewriteTemplate,
			onDeleteRecord,
			isInitialized,
		} = this;
		if (!isInitialized) {
			return (
				<div className='AgGridTemplatesPanel'>
					<Loader />
				</div>
			);
		}

		return (
			<div className='AgGridTemplatesPanel'>
				{[userTemplates, sharedTemplates].map((templates, index) => {
					const isSharedTemplates = index === 1;
					const isUserTemplates = index === 0;
					const targetArray = isUserTemplates ? 'userTemplates' : 'sharedTemplates';
					const title = isSharedTemplates ? t('sharedTemplates') : t('myTemplates');
					const addButtonTitle = isSharedTemplates ? t('addSharedTemplate') : t('addMyTemplate');
					const showControls = isUserTemplates || this.withEditAccess;

					if (isSharedTemplates && templates.length === 0 && !this.withEditAccess) {
						return null;
					}
					return (
						<div className='AgGridTemplatesPanel__List'>
							<AutoAnimate>
								<Text weight='bold' size='m' className='title'>
									{title}
								</Text>
								{templates.map((template, index) => {
									if (template === this.editRecord) {
										return (
											<div className='AgGridTemplatesPanel__Item' key={'new'}>
												<FieldGroup size='s'>
													<TextField
														value={template.name}
														onChange={(value) => onNameChange(template, value)}
														placeholder={t('namePlaceholder')}
													/>
													<Button
														onlyIcon
														iconLeft={IconCheck}
														onClick={(e) => onSaveRecord(template, e)}
														disabled={this.prevName === template.name}
														title={t('save')}
													/>
													<Button
														onlyIcon
														iconLeft={IconCancel}
														view='clear'
														onClick={() => onCancelEdit(template, targetArray)}
														title={t('cancel')}
													/>
												</FieldGroup>
											</div>
										);
									}

									return (
										<div className='AgGridTemplatesPanel__Item' key={template.id}>
											<div className='name' onClick={() => onLoadTemplate(template)}>
												{template.name}
											</div>
											{showControls && (
												<ContextMenuButton
													buttonDisabled={!!this.editRecord}
													size='s'
													items={[
														{
															label: t('rewriteTemplate'),
															iconLeft: IconSwap,
															onClick: () => onRewriteTemplate(template),
														},
														{
															label: t('editTemplate'),
															iconLeft: IconEdit,
															onClick: (e) => onEditRecord(template, e),
														},
														{
															label: t('deleteTemplate'),
															iconLeft: IconTrash,
															status: 'alert',
															onClick: (e) => onDeleteRecord(template, index, targetArray),
														},
													]}
												/>
											)}
										</div>
									);
								})}
							</AutoAnimate>

							{showControls && (
								<Button
									onClick={onAddRecord(targetArray)}
									iconLeft={IconAdd}
									form='round'
									size='s'
									label={addButtonTitle}
									disabled={!!this.editRecord}
								/>
							)}
						</div>
					);
				})}
			</div>
		);
	}
}
