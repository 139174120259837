export default {
	HeaderBreadcrumbs: {
		main: 'Main',
		projects: 'Projects',
		project: 'Project',
		newProject: 'New project',
		tasks: 'Tasks',
		task: 'Task',
		newTask: 'New task',
		managementSettings: 'Management settings',
		salesSettings: 'Sales settings',
		manufactureSettings: 'Manufacture settings',
		documentsSettings: 'Documents settings',
		messagesSettings: 'Messages settings',
		storehouseSettings: 'Storehouse settings',
		contragentsSettings: 'Contragents settings',
		adminSettings: 'Application settings',
		financeSettings: 'Finance settings',
		staffSettings: 'Staff settings',
		test: 'Test page',
		profile: 'Profile settings',
		analytics: 'Analytics',
		orders: 'Orders',
		order: 'Order',
		newOrder: 'New order',
		documents: 'Documents',
		document: 'Document',
		newDocument: 'New document',
		dialogs: 'Dialogs',
		dialog: 'Dialog',
		newDialog: 'New dialog',
		productionOrders: 'Production orders',
		productionOrder: 'Production order',
		newProductionOrder: 'New production order',
		catalog: 'Catalog',
		reports: 'Reports',
		workshops: 'Workshops',
		workshop: 'Workshop',
		newWorkshop: 'New workshop',
		equipments: 'Equipments',
		equipment: 'Equipment',
		newEquipment: 'New equipment',
		processes: 'Processes',
		process: 'Process',
		newProcess: 'New process',
		materials: 'Materials',
		material: 'Material',
		newMaterial: 'New material',
		techMaps: 'Tech maps',
		techMap: 'Tech map',
		newTechMap: 'New tech map',
		nomenclatures: 'Nomenclatures',
		nomenclature: 'Nomenclature',
		newNomenclature: 'New nomenclature',
		nomenclatureMovements: 'Nomenclature movements',
		nomenclatureMovement: 'Nomenclature movement',
		newNomenclatureMovement: 'New Nomenclature movement',
		warehouses: 'Warehouses',
		warehouse: 'Warehouse',
		newWarehouse: 'New warehouse',
		contragents: 'Contragents',
		contragent: 'Contragent',
		newContragent: 'New contragent',
		staff: 'Staff',
		staffMember: 'Staff member',
		newStaffMember: 'New staff member',
		staffSchedule: 'Staff schedule',
		users: 'Users',
		user: 'User',
		newUser: 'New user',
		roles: 'Roles',
		role: 'Role',
		newRole: 'New role',
		operations: 'Operations',
		operation: 'Operation',
		newOperation: 'New operation',
		myCompanies: 'My companies',
	},
};

