import React from 'react';
import { t } from '../i18n';
import { Text } from '@consta/uikit/Text';
/**
 * A component that displays a count number with a label.
 *

 * @param {number} count - The count number to display.
 */

export const Count = ({ count }) => {
	if (!count) return;
	return (
		<div className='KanbanCard__Count'>
			<Text size='xs'>
				{t('count')}: {count}
			</Text>
		</div>
	);
};
