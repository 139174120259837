import React from 'react';
import './Section.scss';
import classNames from 'classnames';

export class Section extends React.Component {
	render() {
		let { children, className,contentClassName, title } = this.props;
		const sectionClassName = classNames('Section', className);
		contentClassName = classNames('Section__content', contentClassName);

		return (
			<section className={sectionClassName}>
				{title && <header>{title}</header>}
				<div className={contentClassName}>{children}</div>
			</section>
		);
	}
}

