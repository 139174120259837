import React from 'react';
import AttachIcon from '@phosphor-icons/core/regular/paperclip.svg';
import CommentIcon from '@phosphor-icons/core/regular/chat-teardrop-text.svg';
import { AvatarGroup } from '@smartplatform/consta/ui/AvatarGroup';
import { Text } from '@consta/uikit/Text';
import './Bottom.scss';

/**
 * A component that renders the bottom section of a Kanban card.
 *
 * @param {Object[]} users - Array of user objects to display in an avatar group.
 * @param {number} commentCount - The number of comments to display.
 * @param {number} attachmentCount - The number of attachments to display.
 * @param {number} [visibleCount=5] - The maximum number of user avatars visible at once.
 *
 */

export const Bottom = ({ users, commentCount, attachmentCount, visibleCount = 5 }) => {
	if (!users?.length && !commentCount && !attachmentCount) return;
	const showCounts = attachmentCount > 0 || commentCount > 0;
	return (
		<div className='KanbanCard__Bottom'>
			{users?.length > 0 && <AvatarGroup items={users} size='s' visibleCount={visibleCount} />}
			{showCounts && (
				<div className='counts'>
					{commentCount > 0 && (
						<Text size='xs' className='commentCount'>
							<CommentIcon view='secondary' size='s' />
							{commentCount}
						</Text>
					)}
					{attachmentCount > 0 && (
						<Text size='xs' className='attachmentCount'>
							<AttachIcon view='secondary' size='s' />
							{attachmentCount}
						</Text>
					)}
				</div>
			)}
		</div>
	);
};
