import { MESSAGES_SETTINGS_PATH } from '../constants';
import { Redirect } from 'react-router-dom';
import React from 'react';
import t from 'i18n';
import { PageNotFound } from '@widgets/PageNotFound';
import { Dictionary } from '@widgets/Dictionary';
import store from '@appStore';
import i18next from 'i18next';

export default (appStore) => [
	{
		path: MESSAGES_SETTINGS_PATH,
		children: <Redirect to={`${MESSAGES_SETTINGS_PATH}/statuses`} />,
		exact: true,
	},
	{
		path: `${MESSAGES_SETTINGS_PATH}/statuses`,
		label: t('dialogStatus.plural'),
		render: (props) => <Dictionary {...props} model={store.model.DialogStatus} />,
		disabled: !appStore.model.DialogStatus || !appStore.model.DialogStatus.INFO.WRITE,
	},
	{
		path: `${MESSAGES_SETTINGS_PATH}/dialog-sources`,
		label: t('dialogSource.plural'),
		render: (props) => (
			<Dictionary
				model={store.model.DialogSource}
				focusProperty='title'
				{...props}
				filterProperties={['title', 'code']}
				listProperties={['title', 'code']}
				editProperties={[
					'title',
					'code',
					{
						name: 'login',
						getLabel: (record) => {
							return record && i18next.exists(`dialogSource.label.${record.code}.login`)
								? t(`dialogSource.label.${record.code}.login`)
								: t(`dialogSource.login}`);
						},
					},
					{
						name: 'secretKey',
						getLabel: (record) => {
							return record && i18next.exists(`dialogSource.label.${record.code}.secretKey`)
								? t(`dialogSource.label.${record.code}.secretKey`)
								: t(`dialogSource.secretKey}`);
						},
					},
				]}
				{...props}
			/>
		),
		disabled: !appStore.model.DialogSource || !appStore.model.DialogSource.INFO.WRITE,
	},
	{
		path: '*',
		component: PageNotFound,
	},
];

