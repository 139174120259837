import React from 'react';
import { ActionbarContext } from './Actionbar';
import { Button } from '@consta/uikit/Button';
import t from 'i18n';

export const CancelButton = () => {
	const { form, cancelTitle, onCancel, noCancel } = React.useContext(ActionbarContext);

	if (noCancel) return null;

	const onBack = async () => {
		await form.back();
		onCancel?.();
	};

	const props = { onClick: onBack };

	props.label = cancelTitle || t('cancel');

	return <Button {...props} view='secondary' />;
};

