import React from 'react';
import { Text } from '@consta/uikit/Text';
import './Title.scss';

/**
 * @property {string} title - title of the card
 * @property {string[]} contragents - list of contragents
 */

export const Title = ({ title, contragents = [] }) => {
	if (!title && !contragents.length) return;
	return (
		<div className='KanbanCard__Title'>
			{title && <Text weight='bold'>{title}</Text>}
			{contragents?.length > 0 && (
				<div className='contragents'>
					{contragents.map((contragentName, index) => (
						<Text view='link' key={index} size='xs'>
							{contragentName}
						</Text>
					))}
				</div>
			)}
		</div>
	);
};
