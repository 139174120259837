import React from 'react';
import { MainInfo } from './MainInfo';
import { PersonalData } from './PersonalData';
import { Education } from './Education';
import { Qualification } from './Qualification';
import { Family } from './Family';
import { WorkExperience } from './WorkExperience';
import { PersonnelDocuments } from './PersonnelDocuments';
import { MilitaryRegistration } from './MilitaryRegistration';
import { STAFF_PATH } from '@pages/staff/constants';
import { PageNotFound } from '@widgets/PageNotFound';

import { t } from 'i18next';

export const tabs = (record) => [
	{
		label: t('staff.mainInfo'),
		path: `${STAFF_PATH}/:contragentId`,
		exact: true,
		children: (props) => <MainInfo {...props} staff={record} path={STAFF_PATH} />,
	},
	{
		label: t('staff.personalData'),
		path: `${STAFF_PATH}/:contragentId/personal`,
		disabled: !record.id,
		children: (props) => <PersonalData {...props} staff={record} />,
	},
	{
		label: t('staff.education'),
		path: `${STAFF_PATH}/:contragentId/education`,
		disabled: !record.id,
		children: (props) => <Education {...props} staff={record} />,
	},
	{
		label: t('staff.qualification'),
		path: `${STAFF_PATH}/:contragentId/qualification`,
		disabled: !record.id,
		children: (props) => <Qualification {...props} staff={record} />,
	},
	{
		label: t('staff.family'),
		path: `${STAFF_PATH}/:contragentId/family`,
		disabled: !record.id,
		children: (props) => <Family {...props} staff={record} />,
	},
	{
		label: t('staff.workExperience'),
		path: `${STAFF_PATH}/:contragentId/workExperience`,
		disabled: !record.id,
		children: (props) => <WorkExperience {...props} staff={record} />,
	},
	{
		label: t('staff.personnelDocuments'),
		path: `${STAFF_PATH}/:contragentId/personnelDocuments`,
		disabled: !record.id,
		children: (props) => <PersonnelDocuments {...props} staff={record} />,
	},
	{
		label: t('staff.militaryRegistration'),
		path: `${STAFF_PATH}/:contragentId/militaryRegistration`,
		disabled: !record.id,
		children: (props) => <MilitaryRegistration {...props} staff={record} />,
	},
	{
		path: '*',
		component: PageNotFound,
	},
];
