import React from 'react';
import { lowerFirst } from 'lodash';
import { Redirect } from 'react-router-dom';
import { PageNotFound } from '@widgets/PageNotFound';
import { Dictionary } from '@widgets/Dictionary';
import store from '@appStore';
import { STAFF_SETTINGS_DICTIONARIES_PATH } from '../../constants';

import t from 'i18n';

export const dictionaryTabs = (accessModels = []) => {
	const tabs = [];
	if (accessModels.length) {
		tabs.push({
			path: STAFF_SETTINGS_DICTIONARIES_PATH,
			children: <Redirect to={`${STAFF_SETTINGS_DICTIONARIES_PATH}/${lowerFirst(accessModels[0])}`} />,
			exact: true,
		});

		for (const modelName of accessModels) {
			tabs.push({
				path: `${STAFF_SETTINGS_DICTIONARIES_PATH}/${lowerFirst(modelName)}`,
				label: t(`${lowerFirst(modelName)}.plural`),
				render: (props) => <Dictionary model={store.model[modelName]} {...props} />,
			});
		}
	}
	tabs.push({
		path: '*',
		component: PageNotFound,
	});

	return tabs;
};

