import React from 'react';
import { KanbanCard } from '@widgets/KanbanCard';
import t from 'i18n';

export default class DialogCard extends React.Component {
	render() {
		const { dialog } = this.props;

		const labels = [];
		const source = dialog.source;

		if (+dialog.countTask) {
			labels.push({ name: t('task.plural') + ' (' + dialog.countTask + ')', color: 'purple' });
		}

		if (+dialog.countOrder) {
			labels.push({ name: t('order.plural') + ' (' + dialog.countOrder + ')', color: 'green' });
		}
		if (+dialog.countDocument) {
			labels.push({ name: t('document.plural') + ' (' + dialog.countDocument + ')', color: 'blue' });
		}

		//TODO тут явно мало данных
		return (
			<KanbanCard>
				<KanbanCard.Top id={dialog.id} updatedAt={dialog.updatedAt} />
				{labels.length > 0 && <KanbanCard.Labels labels={labels} />}
				<KanbanCard.Title title={dialog.name} />
			</KanbanCard>
		);
	}
}

