import { i18nInstance } from 'i18n';
import ru from './ru';
import en from './en';

export const addResourceBundle = () => {
	i18nInstance.addResourceBundle('ru', 'components', ru);
	i18nInstance.addResourceBundle('en', 'components', en);
};

export const t = i18nInstance.getFixedT(null, 'components', 'HeaderBreadcrumbs');

