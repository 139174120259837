import React from 'react';
import { ActionbarContext } from './Actionbar';
import { DeleteConfirmButton } from '@features/DeleteConfirmButton';
import t from 'i18n';

export const DeleteButton = () => {
	const { form, deleteTitle, noDelete, disableDelete, record } = React.useContext(ActionbarContext);
	const [isLoading, setIsLoading] = React.useState(false);

	if (noDelete || !record.id) return null;
	const onDelete = async () => {
		setIsLoading(true);
		await form.delete();
		setIsLoading(false);
	};
	const defaultTitle = t('delete');

	return <DeleteConfirmButton onConfirm={onDelete} label={deleteTitle || defaultTitle} disabled={disableDelete} loading={isLoading} />;
};
