import React from 'react';
import { Badge } from '@consta/uikit/Badge';
import './Labels.scss';

/**
 * @param {Array<{ color: string, name: string } & BadgeProps>} labels - Массив меток
 * @param {import('@consta/uikit/Badge').Size} [size=s] - Размер бейджей

 */

export const Labels = ({ labels, size = 's' }) => {
	if (!labels?.length) return;
	return (
		<div className='KanbanCard__Labels'>
			{labels.map((label, index) => {
				const { color, name, ...rest } = label;
				return <Badge size={size} {...rest} label={name} key={index} style={{ backgroundColor: label.color }} />;
			})}
		</div>
	);
};
