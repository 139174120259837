import React from 'react';
import './KanbanCard.scss';
import { addResourceBundle } from './i18n';
addResourceBundle();
import { List } from './List/List';
import { PositionTotal } from './PositionTotal/PositionTotal';
import { Top } from './Top/Top';
import { Labels } from './Labels/Labels';
import { Title } from './Title/Title';
import { Bottom } from './Bottom/Bottom';
import { Count } from './Count/Count';
import { Progress } from './Progress/Progress';
import  classnames  from 'classnames';

export class KanbanCard extends React.Component {
	render() {
		const { children } = this.props;
		const _className = classnames('KanbanCard', this.props.className);
		return <div className={_className}>{children}</div>;
	}
}

KanbanCard.List = List;
KanbanCard.PositionTotal = PositionTotal;
KanbanCard.Top = Top;
KanbanCard.Labels = Labels;
KanbanCard.Title = Title;
KanbanCard.Bottom = Bottom;
KanbanCard.Count = Count;
KanbanCard.Progress = Progress;
