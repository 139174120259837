import { Text } from '@consta/uikit/Text';
import React from 'react';
import CheckIcon from '@phosphor-icons/core/regular/check.svg';
import { formatDistanceToNowStrict } from 'date-fns';
import { ru } from 'date-fns/locale';
import FireIcon from '@phosphor-icons/core/regular/fire.svg';
import './Top.scss';
import { relative, formatDate } from 'client/tools';
import { t } from '../i18n';

/**
 * @property {number} id - Unique identifier of the card.
 * @property {Date} date - Deadline date of the card.
 * @property {boolean} isClosingStatus - Is the card closing status.
 * @property {number} [warningStep=86400000] - Deadline warning step in milliseconds.
 * @property {boolean} [withDeadline=true] - Should deadline be displayed.
 */

export const Top = ({ id, deadline, isClosingStatus, warningStep = 86400000, updatedAt }) => {
	let icon;
	let iconText;
	if (isClosingStatus) {
		icon = <CheckIcon size='s' view='success' />;
	} else if (deadline) {
		let view;
		const currentDate = new Date();
		const _deadline = new Date(deadline);

		let diffText = formatDistanceToNowStrict(_deadline, { locale: ru });

		if (_deadline < currentDate) {
			view = 'alert';
			diffText = t('overdue');
		} else if (_deadline - currentDate < warningStep) {
			view = 'warning';
		}
		iconText = (
			<Text size='xs' view={view}>
				{diffText}
			</Text>
		);
		icon = <FireIcon size='s' view={view} />;
	}

	const isUpdatedIn2Days = updatedAt && new Date() - new Date(updatedAt) < 86400000 * 2;

	return (
		<div className='KanbanCard__Top'>
			<div className='left'>
				<Text className='id' size='xs'>
					#{id}
				</Text>

				{icon}
				{iconText}
			</div>

			<Text className='date' size='xs'>
				{isUpdatedIn2Days ? relative(updatedAt) : formatDate(updatedAt)}
			</Text>
		</div>
	);
};
