import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { TextField } from '@consta/uikit/TextField';
import { Text } from '@consta/uikit/Text';

import './fieldPassword.scss';
import classNames from 'classnames';
import t from 'i18n';

@observer
export class FieldPassword extends Component {
	@observable isPasswordVisible = false;

	constructor(props) {
		super(props);
	}

	togglePasswordVisible = () => {
		this.isPasswordVisible = !this.isPasswordVisible;
	};

	// При фокусе удалем readonly. Такой костыль, чтобы не автозаполнялось поле само по себе.
	// Так же сделано в Login, Register
	// Возможно стоит сделать hoc
	removeReadOnlyProp = (e) => {
		e.target.removeAttribute('readonly');
	};

	render() {
		const { className, label, error, isRequired, onChange, value, onKeyUp, ...restProps } = this.props;
		const addProps = {};
		if (onKeyUp) addProps.onKeyUp = onKeyUp;
		return (
			<div className={classNames('input-password field', className)}>
				<TextField
					{...restProps}
					onChange={onChange}
					value={value}
					label={label}
					required={isRequired}
					type='password'
					name='password'
					placeholder={t('password.input')}
					caption={error && <Text view='alert'>{error}</Text>}
					onFocus={this.removeReadOnlyProp}
					{...addProps}
				/>
			</div>
		);
	}
}
