import React from 'react';
import { dictionaryTabs } from './tabs';
import { Text } from '@consta/uikit/Text';
import { RouteTabs } from '@smartplatform/consta/ui/RouteTabs';
import { DICTIONARY_DICTIONARIES_MODELS } from '@pages/staff/constants';

import appStore from '@appStore';
import t from '@i18n';

export class DictionariesPage extends React.Component {
	accessModels = [];
	tabs = [];
	constructor(props) {
		super(props);
		this.init();
	}

	init = () => {
		this.accessModels = DICTIONARY_DICTIONARIES_MODELS.filter(
			(modelName) => appStore.model[modelName] && appStore.model[modelName].INFO.READ
		);
		this.tabs = dictionaryTabs(this.accessModels);
	};

	render() {
		if (!this.accessModels.length) return <Text>{t('dictionary.notFound')}</Text>;
		return <RouteTabs items={this.tabs} fitMode='scroll' linePosition='left' />;
	}
}
