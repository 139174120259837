export default {
	_AgGrid: {
		saveSettings: 'Сохранить настройки таблицы',
		resetSettings: 'Сбросить настройки таблицы',
		deleteTemplate: 'Удалить шаблон',
		editTemplate: 'Редактировать название',
		floatingFilter: 'Фильтры под столбцами',
		templates: 'Шаблоны',
		loadTemplate: 'Применить шаблон',
		rewriteTemplate: 'Перезаписать',
		templateLoaded: 'Шаблон "{{template}}" загружен',
		templateRewrited: 'Шаблон "{{template}}" перезаписан текущими настройками таблицы',
		templateSaved: 'Название шаблона "{{oldName}}" изменено на "{{template}}"',
		templateCreated: 'Шаблон "{{template}}" с текущими настройками сохранен',
		templateDeleted: 'Шаблон настроек "{{template}}" удален',
		namePlaceholder: 'Введите название',
		settings: 'Настройки',
		leftSidebar: 'Боковое меню слева',
		theme: 'Тема',
		cancel: 'Отменить',
		save: 'Сохранить',
		myTemplates: 'Мои шаблоны',
		sharedTemplates: 'Общие шаблоны',
		addMyTemplate: 'Добавить мой шаблон',
		addSharedTemplate: 'Добавить общий шаблон',
	},
};

