import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Column } from '@smartplatform/ui';
import { Toolbar, BasicList } from 'components';
import { StaffListStore } from './store';

import store from 'client/store';
import t from 'i18n';

@observer
export class StaffList extends Component {
	constructor(props) {
		super(props);
		this.store = new StaffListStore();
	}
	render() {
		const { path } = this.props;
		const { search, filters, onChange, onSearch } = this.store;
		return (
			<div className='staff-root'>
				<BasicList
					renderCustomToolbar={(props) => <CustomToolbar {...props} onChange={onChange} search={search} onSearch={onSearch} />}
					model={store.model.Contragent}
					filter={{ ...filters }}
					path={path}
					title={t('staff.plural')}
				>
					<Column width={400} property='personName' label={t('staff.fio')} />
					<Column property='status' label={t('staff.status')} />
					<Column property='organization' label={t('staff.organization')} />
					<Column property='personRole' label={t('staff.personRole')} />
					<Column property='phone' label={t('staff.phone')} />
					<Column property='email' label={t('staff.email')} />
				</BasicList>
			</div>
		);
	}
}

const CustomToolbar = (props) => (
	<Toolbar className='model-list-toolbar'>
		<Toolbar.CreateButton onClick={props.onClick} label={t('staff.create')} />
		<Toolbar.Search value={props.search} onSearch={props.onSearch} placeholder={t('staff.search')} />
		<Toolbar.Count text={t('staff.total')} count={props.totalCount} />
	</Toolbar>
);
