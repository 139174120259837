import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { StaffEdit } from './StaffEdit';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
import { StaffList } from './StaffList';

import t from 'i18n';
const modelName = 'Contragent';

export const Staff = ({ match }) => {
	const { path } = match;
	const props = { path, modelName };
	return (
		<Switch>
			<Route path={path} exact component={withTitleAndLink(StaffList, { title: t('staff.plural'), componentProps: props })} />
			<Route
				path={`${path}/:id`}
				component={withTitleAndLink(StaffEdit, { title: t('staff.title'), backRoute: { path: path }, componentProps: props })}
			/>
		</Switch>
	);
};
