import React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'components';
import { Switch } from '@smartplatform/consta/ui/Switch';
import { AutoComplete } from '@consta/uikit/AutoComplete';
import { RecordSelect } from '@smartplatform/consta/ui/RecordSelect';
import { PhoneInput } from '@smartplatform/consta/ui/PhoneInput';
import { DatePicker } from '@smartplatform/consta/ui/DatePicker';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Button } from '@consta/uikit/Button';
import { TextField } from '@consta/uikit/TextField';
import { Text } from '@consta/uikit/Text';
import { DocumentList } from './PersonalDocumentList';
import { DocumentEdit } from './PersonalDocumentEdit';
import StaffStore from '../store';

import IconMail from '@phosphor-icons/core/regular/at.svg';
import IconPhone from '@phosphor-icons/core/regular/phone.svg';
import IconMap from '@phosphor-icons/core/regular/map-pin-area.svg';

import store from '@appStore';
import t from 'i18n';
import './PersonalData.scss';

@observer
export class PersonalData extends React.Component {
	constructor(props) {
		super(props);
		this.store = new StaffStore({ record: this.props.staff });
	}

	render() {
		const formProps = {
			className: 'staff-form staff-form-personal',
			record: this.store.record,
			saveTitle: this.store.record.id ? t('staff.save') : undefined,
			disableSave: !this.store.record.contragentId,
			onCancel: this.store.back,
			stay: true,
			onDelete: this.store.back,
		};

		return (
			<>
				<Form {...formProps}>
					<div className='staff-form-container'>
						<Text size='m' weight='bold'>
							{t('staff.citizenship')}
						</Text>
						<Grid gap='xl' cols={2}>
							<GridItem>
								<RecordSelect
									model={store.model.Country}
									label={t('staff.countryName')}
									placeholder={t('select')}
									property='name'
									onChange={this.store.onChange('country')}
									value={this.store.record.country}
								/>
							</GridItem>
						</Grid>
						<Grid gap='xl' cols={4}>
							<GridItem>
								<TextField
									value={this.store.record.inn}
									type='text'
									label={t('staff.inn')}
									onChange={this.store.onChange('inn')}
								/>
							</GridItem>
							<GridItem>
								<TextField
									value={this.store.record.snils}
									type='text'
									label={t('staff.snils')}
									onChange={this.store.onChange('snils')}
								/>
							</GridItem>
						</Grid>
						<Grid gap='xl' cols={2}>
							<GridItem>
								<DatePicker
									format='dd.MM.yyyy'
									value={this.store.record.informationIsValidAt ? new Date(this.store.record.informationIsValidAt) : null}
									label={t('staff.citizenshipValidAt')}
									onChange={this.store.onChange('informationIsValidAt')}
								/>
							</GridItem>
						</Grid>
					</div>
					<hr />
					<div className='staff-form-container'>
						<Text size='m' weight='bold'>
							{t('staff.identityDocument')}
						</Text>
						<Grid gap='xl' cols={2}>
							<GridItem>
								<TextField
									value={this.store.personalDocument?.type?.name || ''}
									type='text'
									label={t('staff.documentType')}
									disabled
								/>
							</GridItem>
						</Grid>
						<Grid gap='xl' cols={4}>
							<GridItem>
								<TextField value={this.store.personalDocument.series} type='text' label={t('staff.series')} disabled />
							</GridItem>
							<GridItem>
								<TextField value={this.store.personalDocument.number} type='text' label={t('staff.number')} disabled />
							</GridItem>
						</Grid>
						<Grid gap='xl'>
							<GridItem>
								<TextField
									value={this.store.personalDocument.issuedByWhom}
									type='text'
									label={t('staff.issuedByWhom')}
									disabled
								/>
							</GridItem>
						</Grid>
						<Grid gap='xl'>
							<GridItem>
								<TextField
									value={this.store.personalDocument.placeOfBirth}
									type='text'
									label={t('staff.placeOfBirth')}
									disabled
								/>
							</GridItem>
						</Grid>
						<Grid gap='xl' cols={4}>
							<GridItem>
								<TextField
									value={this.store.personalDocument.departmentCode}
									type='text'
									label={t('staff.departmentCode')}
									disabled
								/>
							</GridItem>
						</Grid>
						<Grid gap='xl' cols={4}>
							<GridItem>
								<DatePicker
									value={new Date(this.store.personalDocument.dateOfIssue)}
									label={t('staff.dateOfIssue')}
									disabled
								/>
							</GridItem>
							<GridItem>
								<DatePicker
									value={new Date(this.store.personalDocument.expirationDate)}
									label={t('staff.expirationDate')}
									disabled
								/>
							</GridItem>
						</Grid>
						<Grid gap='xl' cols={2}>
							<GridItem>
								<DatePicker
									value={new Date(this.store.personalDocument.informationDocumentValidAt)}
									label={t('staff.informationDocumentValidAt')}
									disabled
								/>
							</GridItem>
						</Grid>
					</div>
					<Button
						view='ghost'
						className='staff-form-all-documents-btn'
						label={t('staff.allDocuments')}
						onClick={this.store.openDocuments}
					/>
					<hr />
					<div className='staff-form-container'>
						<Text size='m' weight='bold'>
							{t('staff.contact')}
						</Text>
						<Grid gap='xl' cols={2}>
							<GridItem>
								<TextField
									value={this.store.record.email}
									type='text'
									label={t('staff.email')}
									onChange={this.store.onChange('email')}
									leftSide={IconMail}
								/>
							</GridItem>
						</Grid>
						<Grid gap='xl' cols={2}>
							<GridItem>
								<PhoneInput
									value={this.store.record.phone}
									label={t('staff.phone')}
									onChange={this.store.onChange('phone')}
									leftSide={IconPhone}
								/>
							</GridItem>
						</Grid>
						<Grid gap='xl' cols={2}>
							<GridItem>
								<TextField
									value={this.store.record.additionalContact}
									type='text'
									label={t('staff.additionalContact')}
									onChange={this.store.onChange('additionalContact')}
								/>
							</GridItem>
						</Grid>
					</div>
					<hr />
					<div className='staff-form-container'>
						<Text size='m' weight='bold'>
							{t('staff.address')}
						</Text>
						<Grid gap='xl' cols={1}>
							<GridItem>
								<AutoComplete
									type='text'
									label={t('staff.addressRegister')}
									value={this.store.record.addressRegister}
									items={this.store.suggestions || []}
									leftSide={IconMap}
									onChange={this.store.onChange('addressRegister', true)}
								/>
							</GridItem>
						</Grid>
						<Grid gap='xl' cols={1}>
							<GridItem>
								<AutoComplete
									type='text'
									label={t('staff.addressFact')}
									value={this.store.record.addressFact}
									items={this.store.suggestions || []}
									leftSide={IconMap}
									onChange={this.store.onChange('addressFact', true)}
								/>
								<Switch
									value={this.store.isAddressFactCopy}
									className='toggle-copy-address'
									onChange={this.store.onToggleCopyAddressFact}
									leftSide={IconMap}
									label={t('staff.isAddressFactCopy')}
								/>
							</GridItem>
						</Grid>
						<Grid gap='xl' cols={1}>
							<GridItem>
								<AutoComplete
									type='text'
									label={t('staff.addressInfo')}
									value={this.store.record.addressInfo}
									items={this.store.suggestions || []}
									leftSide={IconMap}
									onChange={this.store.onChange('addressInfo', true)}
								/>
								<Switch
									value={this.store.isAddressInfoCopy}
									className='toggle-copy-address'
									onChange={this.store.onToggleCopyAddressInfo}
									label={t('staff.isAddressInfoCopy')}
								/>
							</GridItem>
						</Grid>
						<Grid gap='xl' cols={1}>
							<GridItem>
								<AutoComplete
									type='text'
									label={t('staff.addressForeign')}
									value={this.store.record.addressForeign}
									items={this.store.suggestions || []}
									leftSide={IconMap}
									onChange={this.store.onChange('addressForeign', true)}
								/>
							</GridItem>
						</Grid>
					</div>
				</Form>
				<DocumentList store={this.store} />
				<DocumentEdit store={this.store} />
			</>
		);
	}
}
