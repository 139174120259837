import { observable } from 'mobx';
import { STAFF_LOCAL_STORAGE_FIELDS } from './constants';

import debounce from 'lodash/debounce';
import store from 'client/store';

export class StaffListStore {
	@observable search = '';
	@observable filters = {};

	constructor() {
		this.loadLocalStorage();
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.compileFilter();
	}

	compileFilter = () => {
		const { search } = this;

		const filter = {
			where: {
				contragentId: { neq: null },
				and: [],
			},
		};

		const trimmed = search.trim();
		if (trimmed.length) {
			const words = trimmed.split(' ');
			words.forEach((word) =>
				filter.where.and.push({
					or: [{ personName: { ilike: `%${word}%` } }],
				})
			);
		}

		this.filters = filter;
	};

	onChange =
		(prop, autoApply = true) =>
		(value) => {
			this[prop] = value;
			if (autoApply) {
				this.saveToLocalStorage();
				this.compileFilter();
			}
		};

	doSearch = () => {
		this.saveToLocalStorage();
		this.compileFilter();
	};

	onSearch = (value) => {
		this.search = value || '';
		this.doSearch();
	};

	saveToLocalStorage = () => {
		const { staff: localStorage } = store.local;
		STAFF_LOCAL_STORAGE_FIELDS.forEach((field) => (localStorage[field] = this[field]));
	};

	loadLocalStorage = () => {
		const { staff: localStorage } = store.local;
		const storageKeys = Object.keys(localStorage);
		if (storageKeys.length)
			storageKeys.forEach((key) => {
				this[key] = localStorage[key];
			});
	};
}
