import appStore from 'client/store';
import { AgGridTemplatesPanel } from './AgGridTemplatesPanel/AgGridTemplatesPanel';
import { SettingsToolPanel } from './settings/Settings';
import { t } from '../i18n';

export const getSideBar = ({ gridRef, templateCode, withEditAccess }) => ({
	toolPanels: [
		{
			id: 'columns',
			labelDefault: 'Columns',
			labelKey: 'columns',
			iconKey: 'columns',
			toolPanel: 'agColumnsToolPanel',
			toolPanelParams: {
				suppressPivots: true,
				suppressRowGroups: true,
			},
		},
		{
			id: 'filters',
			labelDefault: 'Filters',
			labelKey: 'filters',
			iconKey: 'filter',
			toolPanel: 'agFiltersToolPanel',
		},
		templateCode && {
			id: 'templates',
			labelDefault: t('templates'),
			labelKey: 'templates',
			iconKey: 'menu',
			toolPanel: () => <AgGridTemplatesPanel code={templateCode} gridRef={gridRef} withEditAccess={withEditAccess} />,
		},
		{
			id: 'settings',
			labelDefault: t('settings'),
			labelKey: 'settings',
			iconKey: 'settings',
			toolPanel: () => <SettingsToolPanel gridRef={gridRef} />,
		},
	].filter(Boolean),
	position: appStore.local.agGrid.leftSidebar ? 'left' : 'right',
});

