import React from 'react';
import { Sidebar } from '@consta/uikit/Sidebar';
import { observer } from 'mobx-react';
import { Text } from '@consta/uikit/Text';
import { t, exists } from 'i18next';
import { TextField } from '@consta/uikit/TextField';
import { getLabelName } from 'client/tools';
import { NumberInput } from '@smartplatform/consta/ui/NumberInput';
import { Switch } from '@smartplatform/consta/ui/Switch';
import { DatePicker } from '@smartplatform/consta/ui/DatePicker';
import { RecordSelect } from '@smartplatform/consta/ui/RecordSelect';
import appStore from '@appStore';
import { ColorPicker } from '@features/ColorPicker';
import { lowerFirst } from 'lodash';
import { addResourceBundle } from '../i18n';
import { withTranslation } from 'react-i18next';
import { addAlertNotification } from '@smartplatform/consta/ui/Notifications';
import './EditRecord.scss';
import { Form } from 'components';
addResourceBundle();

@withTranslation('components', { keyPrefix: 'Dictionary' })
@observer
export class EditRecord extends React.Component {
	onChangeProp = (prop) => (value) => {
		if (this.props.record.hasOwnProperty(prop)) {
			this.props.record[prop] = value;
		}
	};

	onChangeRelation = (relation) => (value) => {
		const findRelation = this.props.relationsForEdit.find(({ name }) => name === relation);
		if (findRelation) {
			this.props.record[relation] = value;
			this.props.record[findRelation.foreignKey] = value ? value.id : null;
		}
	};

	componentDidUpdate(prevProps) {
		if (this.props.record && prevProps.record !== this.props.record) {
			const focusEl = document.getElementById('focusProperty');
			if (focusEl) {
				setTimeout(() => focusEl.focus(), 300);
			}
		}
	}
	renderField = ({ key: propertyName, required, type, computed, getDisabled, getLabel }, index) => {
		const { model, record, focusProperty } = this.props;
		const { onChangeProp } = this;
		const label = getLabel?.(record) || getLabelName(propertyName, model.INFO.name);
		const isDisabled = !model.INFO.WRITE || getDisabled?.(record);

		const fieldProps = {
			label,
			placeholder: label,
			value: record[propertyName],
			onChange: onChangeProp(propertyName),
			required,
			disabled: isDisabled,
			id: focusProperty === propertyName ? 'focusProperty' : undefined,
		};

		const fieldComponentMap = {
			color: ColorPicker,
			Number: NumberInput,
			Boolean: Switch,
			Date: DatePicker,
			default: TextField,
		};

		const FieldComponent = fieldComponentMap[type] || fieldComponentMap[propertyName] || fieldComponentMap.default;

		return computed ? computed({ record, fieldProps }) : <FieldComponent key={propertyName} {...fieldProps} />;
	};

	defaultGetItemLabel = (record) => record.name;

	render() {
		const {
			record,
			onCloseSidebar,
			fieldsForEdit,
			relationsForEdit,
			model,
			t: widgetTranslate,
			onSave,
			onDelete,
			checkRequiredFields,
			getDisableSave,
		} = this.props;

		const { onChangeRelation } = this;
		const isDirty = record?.isDirty;
		const isFormDisabled = getDisableSave?.(record);
		return (
			<Sidebar
				isOpen={!!record}
				onEsc={onCloseSidebar}
				onClickOutside={isDirty ? undefined : onCloseSidebar}
				className='Dictionary-edit'
			>
				{record && (
					<>
						<div className='Dictionary-edit-head'>
							<Text size='2xl' lineHeight='xs' weight='semibold'>
								{!record.id ? widgetTranslate('addRecord') : widgetTranslate('editRecord')}
							</Text>
						</div>
						<Form
							record={record}
							onSave={onSave}
							onCancel={onCloseSidebar}
							onDelete={onDelete}
							className='Dictionary-edit-content'
							disableSave={!isDirty || isFormDisabled}
							checkRequiredFields={checkRequiredFields}
						>
							{!!fieldsForEdit.length && fieldsForEdit.map((record, index) => this.renderField(record, index))}
							{!!relationsForEdit.length &&
								relationsForEdit.map((relation, index) => {
									const { model: modelName, name, property, computed, getFilter } = relation;
									const labelName = lowerFirst(model.INFO.name) + '.' + name;
									let label = exists(labelName) && t(labelName);
									if (!label) label = exists(getLabelName('title', name)) ? getLabelName('title', name) : name;
									const getItemLabel = computed || this.defaultGetItemLabel;
									const filter = getFilter?.(record);
									return (
										<RecordSelect
											key={`relation_${name}_${property}`}
											model={appStore.model[modelName]}
											label={label}
											getItemLabel={getItemLabel}
											placeholder={t('select')}
											value={record[name]}
											onChange={onChangeRelation(name)}
											disabled={!model.INFO.WRITE}
											filter={filter}
										/>
									);
								})}
						</Form>
					</>
				)}
			</Sidebar>
		);
	}
}
