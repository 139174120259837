import React from 'react';
import { observer } from 'mobx-react';
import { Table, Column } from '@smartplatform/ui';
import { Button } from '@consta/uikit/Button';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import IconPlus from '@phosphor-icons/core/regular/plus-circle.svg';

import t from 'i18n';

@observer
export class DocumentList extends React.Component {
	render() {
		const { store } = this.props;
		return (
			<>
				{store.isViewDocuments && (
					<Modal
						isOpen={true}
						className='staff-documents-popup staff-documents-popup-list'
						onClickOutside={!store.document && store.onClose}
						onEsc={!store.document && store.onClose}
					>
						<div className='staff-documents-content'>
							<Text size='2xl' weight='bold'>
								{t('staff.docsHistory')}
							</Text>
							<Table rows={store.documents} onRowClick={store.editDocument}>
								<Column relation='type' property='name' label={t('staff.documentType')} />
								<Column property='series' label={t('staff.series')} />
								<Column property='number' label={t('staff.number')} />
								<Column property='issuedByWhom' label={t('staff.issuedByWhom')} />
							</Table>
							<div>
								<Button
									view='secondary'
									label={t('staff.addDocument')}
									onClick={store.createDocument}
									iconLeft={IconPlus}
								/>
							</div>
						</div>
						<div className='staff-documents-footer'>
							<Button view='secondary' label={t('close')} onClick={store.onClose} />
						</div>
					</Modal>
				)}
			</>
		);
	}
}
