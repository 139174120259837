import React from 'react';
import { observer } from 'mobx-react';
import { DatePicker } from '@smartplatform/consta/ui/DatePicker';
import { RecordSelect } from '@smartplatform/consta/ui/RecordSelect';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Select } from '@consta/uikit/Select';
import { TextField } from '@consta/uikit/TextField';
import { Text } from '@consta/uikit/Text';
import { Form } from 'components';
import StaffStore from '../store';

import store from '@appStore';
import t from 'i18n';

@observer
export class MainInfo extends React.Component {
	constructor(props) {
		super(props);
		this.store = new StaffStore({ record: this.props.staff });
	}

	render() {
		const formProps = {
			className: 'staff-form',
			record: this.store.record,
			saveTitle: this.store.record.id ? t('staff.save') : undefined,
			disableSave: !this.store.record.contragentId,
			onCancel: this.store.back,
			stay: true,
			onDelete: this.store.back,
		};

		return (
			<Form {...formProps}>
				<div className='staff-form-container'>
					<Text size='m' weight='bold'>
						{t('staff.personalInfo')}
					</Text>
					<Grid cols={1}>
						<GridItem>
							<TextField
								value={this.store.record.personName}
								type='text'
								label={t('staff.fio')}
								onChange={this.store.onChange('personName')}
							/>
						</GridItem>
					</Grid>
					<Grid gap='xl' cols={2}>
						<GridItem>
							<DatePicker
								format='dd.MM.yyyy'
								value={this.store.record.birthDay ? new Date(this.store.record.birthDay) : null}
								label={t('staff.birthDay')}
								onChange={this.store.onChange('birthDay')}
							/>
						</GridItem>
						<GridItem>
							<Select
								items={['male', 'female']}
								value={this.store.record.sex}
								onChange={this.store.onChange('sex')}
								getItemKey={(value) => value}
								getItemLabel={(value) => t(`staff.${value}`)}
								label={t('staff.sex')}
							/>
						</GridItem>
					</Grid>
				</div>
				<hr />
				<div className='staff-form-container'>
					<Text size='m' weight='bold'>
						{t('client.title')}
					</Text>
					<Grid cols={1}>
						<GridItem>
							<RecordSelect
								model={store.model.Contragent}
								label={t('staff.forContragent')}
								placeholder={t('select')}
								property='name'
								onChange={this.store.onChange('contragent')}
								value={this.store.record.contragent}
								isRequired
							/>
						</GridItem>
					</Grid>
				</div>
			</Form>
		);
	}
}
