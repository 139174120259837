import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { processText } from 'client/tools';
import { Attachments, Editor, Log } from 'components';
import { ActionModal } from '@smartplatform/consta/ui/ActionModal';
import { EditButton } from '@smartplatform/consta/ui/EditButton';
import store from 'client/store';
import t from 'i18n';
import { DeleteConfirmButton } from '@features/DeleteConfirmButton';
import { Button } from '@consta/uikit/Button';

import './style.scss';

@observer
export class Comment extends React.Component {
	static propTypes = {
		className: PropTypes.string,
	};

	@observable isEditMode = false;
	@observable popupImage = null;
	@observable showImage = false;
	@observable showChangedPopup = false;
	ref = React.createRef();
	changed = false;

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		const isMentionBtn = event.target.hasAttribute('data-mention-btn') || event.target.classList.contains('rte-autocomplete');
		if (this.isEditMode && this.ref && !this.ref.current?.contains(event.target) && !isMentionBtn) {
			if (this.changed) {
				this.showChangedPopup = true;
			} else {
				this.onCancel();
			}
		}
	};

	onEditorInit = (editor) => {
		this.editor = editor;
	};

	onTextChange = (value) => {
		this.props.comment.text = value;
		this.changed = true;
	};

	toggleEditMode = (value) => {
		if (value) {
			document.addEventListener('mousedown', this.handleClickOutside);
		} else {
			document.removeEventListener('mousedown', this.handleClickOutside);
		}
		this.isEditMode = value;
	};

	edit = () => {
		this.toggleEditMode(true);
		this.origValue = this.props.comment.text;
	};

	onSave = async () => {
		if (this.changed) {
			const images = await this.uploadImages();

			this.props.comment.text = this.editor.getContent();
			await this.props.comment.save();

			for (let imgData of images) {
				const { element, uploadUri } = imgData;
				const filename = uploadUri.split('/').slice(-1)[0];
				const id = filename ? parseInt(filename.replace(/-filename/, '')) : null;
				if (id) await this.props.comment.attachments.add(id);
			}
		}
		this.toggleEditMode(false);
		this.changed = false;
	};

	onCancel = () => {
		if (!this.showChangedPopup && this.isChanged) {
			this.showChangedPopup = true;
			return;
		}

		this.props.comment.text = this.origValue;
		this.changed = false;
		this.toggleEditMode(false);
		this.showChangedPopup = false;
	};

	onOpenConfirmPopup = () => (this.showChangedPopup = true);
	onCloseConfirmPopup = () => (this.showChangedPopup = false);

	uploadImages = () =>
		new Promise((resolve, reject) => {
			this.editor.uploadImages((success) => {
				if (success) {
					resolve(success);
				} else {
					reject();
				}
			});
		});

	hideImage = () => (this.showImage = false);

	get isChanged() {
		return this.props.comment.text !== this.origValue;
	}

	render() {
		const { onCloseConfirmPopup, onCancel, onSave } = this;
		const { comment, className, roles = [] } = this.props;
		const attachments = comment.attachments();
		const isUserTheOwner = store.model.user.id === comment.ownerId;
		const renderAttachments = (
			<Attachments record={comment} canUpload={isUserTheOwner} canDelete={isUserTheOwner} attachments={attachments} />
		);
		const renderPanelButton =
			store.model.user && isUserTheOwner ? (
				<div className='panel-buttons'>
					{this.isEditMode ? (
						<div className='toggle'>
							<Button label={t('save')} onClick={onSave} disabled={!comment.text || !this.isChanged} />
							<Button label={t('cancel')} onClick={onCancel} view='clear' />
						</div>
					) : (
						<EditButton label={t('edit')} onClick={this.edit} view='clear' />
					)}

					{renderAttachments}

					<DeleteConfirmButton
						label={t('comment.delete')}
						onConfirm={() => this.props.onDelete(comment)}
						confirmMessage={t('comment.confirmDelete')}
					/>
				</div>
			) : (
				renderAttachments
			);

		const content = (
			<div className='comment' ref={this.ref}>
				<div className='text'>
					{this.isEditMode ? (
						<Editor
							autoFocus
							key={comment.id}
							value={comment.text}
							onChange={this.onTextChange}
							mediaModel={store.model.Attachment}
							onInit={this.onEditorInit}
							height={250}
							menubar={false}
							mentions={this.props.mentions}
							toolbar='undo redo | bold italic | alignleft aligncenter alignjustify alignright | numlist bullist | blockquote | link image media'
						/>
					) : (
						<div className='rich-text' dangerouslySetInnerHTML={{ __html: processText(comment.text) }} />
					)}
				</div>
				{renderPanelButton}
			</div>
		);

		return (
			<>
				<Log instanceLog={comment} roles={roles} actions={[{ title: t('history.leftComment') }]} content={content} />
				<ActionModal
					isOpen={this.showChangedPopup}
					onClose={onCloseConfirmPopup}
					onEsc={onCloseConfirmPopup}
					onConfirm={onCancel}
					action={t('comment.confirmTitle')}
					description={t('comment.confirmDescription')}
				/>
			</>
		);
	}
}

